.slider-style-about 
{
    padding-top: 219px;
}
.dispmobile
{
    display: block;
}

.w-80
{
    width: 80%;
    height: auto;
}

p.abtpara {
    font-size: 20px;
}

h4.titley {
    color: #ffff01;
    font-size: 32px;
    text-align: center;
}
.integrity {
    width: 120px;
    height: auto;
    padding-bottom: 39px;
    text-align: center;
    margin: 0 auto;
}



p.see {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.3px;
    line-height: 14px;
    color: #000000;
    border-bottom: 3px solid #e5e542;
    padding: 0 0 10px 0;
    display: inline-block;
    margin-bottom: 16px;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}

p.see:hover {
    border-bottom: 3px solid #000;
}


.counter-title {
    margin-top: 8px;
    font-weight: 400;
    font-size: 26px;
    color: #000;
}

h4.cnum {
    font-size: 74px;
    font-weight: 900;
    color: #000;
}

.counter-style-4 .counter-title {
    margin-top: 8px;
    font-weight: 400;
    font-size: 24px;
    color: #000;
}
.counter-style-4 .count-number {
    font-size: 72px;
    color: #000;
}
.count-number span {
    -webkit-text-stroke: 1px #000;
    -webkit-text-fill-color: #000000;
  }



.buttn {
    margin-top: 60px;
}


.button3 {
	color: #ffff01;
    background: #000;
	border-radius: 8px;
	transition: .2s ease-in-out;
    padding: 20px 40px;
    margin-right: 20px;
    font-size: 22px;
}

.button3:hover {
	border-radius: 30px;
}

.orange-button-row .avia-button {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    padding: 0 90px 0 15px !important;
    min-height: 50px;
    line-height: 60px;
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    min-width: 320px;
    background-repeat: no-repeat;
    background-position: right 15px center;
    padding-top: 5px !important;
    border: 0 !important;
}

.rn-section-tbpadding
{
    padding-top: 30px;
    padding-bottom: 30px;
}

.rn-section-integrity
{
    padding-top: 232px;
    padding-bottom: 231px;
}

.rn-section-counterpadding
{
    padding-top: 380px;
    padding-bottom: 390px;
}






/* Start Responsiveness */

@media only screen and (max-width: 1440px) and (min-width: 1200px)
{
    .rn-section-integrity
    {
        padding-top: 150px;
        padding-bottom: 150px;
    }
    
    .rn-section-counterpadding
    {
        padding-top: 305px;
        padding-bottom: 305px;
    }
    .slider-style-about 
    {
        padding-top: 204px;
    }
}



@media only screen and (max-width: 1200px) and (min-width: 1024px)
{
    .rn-section-integrity
    {
        padding-top: 120px;
        padding-bottom: 120px;
    }
    
    .rn-section-counterpadding
    {
        padding-top: 306px;
        padding-bottom: 306px;
    }
    .slider-style-about 
    {
        padding-top: 275px;
    }
}



@media only screen and (max-width: 1024px) and (min-width: 960px)
{
    .rn-section-integrity
    {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    
    .rn-section-counterpadding
    {
        padding-top: 305px;
        padding-bottom: 305px;
    }
    .slider-style-about {
        padding-top: 230px;
    }
    .pb-60 {
        padding-bottom: 60px;
    }
}



@media only screen and (max-width: 960px) and (min-width: 768px)
{
    .rn-section-integrity
    {
        padding-top: 60px;
        padding-bottom: 40px;
    }
    
    .rn-section-counterpadding
    {
        padding-top: 300px;
        padding-bottom: 295px;
    }

    .slider-style-about {
        padding-top: 236px;
    }
    .pb-60 {
        padding-bottom: 40px;
    }

    h4.titley {
        font-size: 28px;
    }
    h4.cnum {
        font-size: 60px;
    }
    .integrity {
        width: 90px;
        padding-bottom: 15px;
    }
    .textcenter_inq {
        text-align: center;
    }
}



@media only screen and (max-width: 768px) and (min-width: 520px)
{
    .rn-section-integrity
    {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    
    .rn-section-counterpadding
    {
        padding-top: 300px;
        padding-bottom: 300px;
    }
    .slider-style-about {
        padding-top: 295px;
    }
    .pb-60 {
        padding-bottom: 50px;
    }

    h4.titley {
        font-size: 26px;
    }
    h4.cnum {
        font-size: 56px;
    }
    p.abtpara {
        font-size: 18px;
    }
    .button3 {
        padding: 16px 24px;
        margin-right: 10px;
        font-size: 18px;
    }
    .integrity {
        width: 90px;
        padding-bottom: 15px;
    }
    .textcenter_inq {
        text-align: center;
    }
}



@media only screen and (max-width: 520px) and (min-width: 425px)
{
    .rn-section-integrity
    {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    
    .rn-section-counterpadding
    {
        padding-top: 88px;
        padding-bottom: 85px;
    }
    .slider-style-about {
        padding-top: 315px;
    }
    .pb-60 {
        padding-bottom: 112px;
    }
    h4.titley {
        font-size: 26px;
    }
    h4.cnum {
        font-size: 50px;
    }
    p.abtpara {
        font-size: 18px;
    }
    .buttn {
        margin-top: 15px;
        display: inline-block;
    }
    .button3 {
        padding: 14px 45px;
        margin-right: 0px;
        margin-bottom: 10px;
        font-size: 18px;
        display: block;
    }
    .integrity {
        width: 90px;
        padding-bottom: 15px;
    }
    .counter-title {
        margin-top: 4px;
        font-size: 22px;
        margin-bottom: 40px;
    }
    p.see {
        display: block;
        width: 200px;
        font-size: 18px;
        text-align: center;
        margin: 0 auto;
    }
    .reversesection {
        flex-direction: column-reverse;
    }
    .textcenter {
        text-align: center;
    }
    .textcenter_inq {
        text-align: center;
    }
}



@media only screen and (max-width: 425px) and (min-width: 320px)
{
    .rn-section-integrity
    {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    
    .rn-section-counterpadding
    {
        padding-top: 88px;
        padding-bottom: 85px;
    }
    .slider-style-about {
        padding-top: 280px;
    }
    .pb-60 {
        padding-bottom: 112px;
    }
    h4.titley {
        font-size: 26px;
    }
    h4.cnum {
        font-size: 50px;
    }
    p.abtpara {
        font-size: 18px;
    }
    .buttn {
        margin-top: 15px;
        display: inline-block;
    }
    .button3 {
        padding: 14px 45px;
        margin-right: 0px;
        margin-bottom: 10px;
        font-size: 18px;
        display: block;
    }
    .integrity {
        width: 90px;
        padding-bottom: 15px;
    }
    .counter-title {
        margin-top: 4px;
        font-size: 22px;
        margin-bottom: 40px;
    }
    p.see {
        display: block;
        width: 200px;
        font-size: 18px;
        text-align: center;
        margin: 0 auto;
    }
    .reversesection {
        flex-direction: column-reverse;
    }
    .textcenter {
        text-align: center;
    }
    .textcenter_inq {
        text-align: center;
    }
}


/* End Responsiveness */
