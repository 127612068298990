/*==========================
    Contact Address 
============================*/

.contact-form-1 {
    .form-group {
        input {
            height: 70px;
            padding: 0 20px;
        }
        textarea {
            padding-top: 20px;
            padding-left: 20px;
        }
    }
}


.contact-address-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

/*-------------------
    Address Area  
--------------------*/
.rn-address {
    margin-top: 30px;
    padding: 60px 40px;
    border-radius: 10px;
    background: var(--color-blackest);
    box-shadow: 0 0 20px rgba(0,0,0,0.05);
    transition: 0.3s;

    @media #{$lg-layout} {
        padding: 30px 20px;
    }
    @media #{$md-layout} {
        padding: 30px 20px;
    }
    @media #{$sm-layout} {
        padding: 30px 20px;
    }
    
    .icon {
        color: #ffff01;
        border-radius: 100%;
        font-size: 40px;
        display: inline-block;
        margin-bottom: 17px;
        line-height: 6px;
    }
    .inner {
        h4 {
            &.title {
                font-size: 26px;
                font-weight: 700;
            }
        }
        p {
            font-size: 18px;
            color: #acacac;
            margin-bottom: 0;
            @media #{$lg-layout} {
                font-size: 16px;
            }
            @media #{$md-layout} {
                font-size: 16px;
            }
            @media #{$sm-layout} {
                font-size: 16px;
            }
            br {
                
                @media #{$lg-layout} {
                    display: none;
                }

                @media #{$md-layout} {
                    display: none;
                }

                @media #{$sm-layout} {
                    display: none;
                }
            }
            a {
                color: var(--color-body);
                @extend %transition;
                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }
    &:hover {
        transform: translateY(-5px)
    }
}


.google-map-style-1 {
    width: 100%;
    height: 550px;
    border-radius: 10px;
    div {
        border-radius: 10px;
    }
}

.success-message {
    color: green;
    margin-top: 20px;
    font-size: 16px;
}




.rn-section-contpadding {
    padding-top: 344px;
    padding-bottom: 344px;
}

.rn-section-formpadding {
    padding-top: 120px;
    padding-bottom: 120px;
}

.rn-address {
    min-height: 280px;
}

input.btn-submit {
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    border-radius: 8px;
    background-color: transparent;
    background: #000 !important;
    transition-duration: 300ms;
    color: #ffff01;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    text-shadow: var(--shadow-light);
    display: inline-block;
    letter-spacing: 0.5px;
    width: auto;
    height: 60px;
    line-height: 56px;
    padding: 0 37px;
    font-size: 18px;
}





@media only screen and (max-width: 1440px) and (min-width: 1200px)
{
    .rn-section-contpadding {
        padding-top: 265px;
        padding-bottom: 264px;
    }
    
    .rn-section-formpadding {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .rn-address {
        min-height: 280px;
    }
    
}


@media only screen and (max-width: 1200px) and (min-width: 1024px)
{
    .rn-section-contpadding {
        padding-top: 259px;
        padding-bottom: 259px;
    }
    
    .rn-section-formpadding {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .rn-address {
        min-height: 286px;
    }
}


@media only screen and (max-width: 1024px) and (min-width: 960px)
{
    .rn-section-contpadding {
        padding-top: 291px;
        padding-bottom: 292px;
    }
    
    .rn-section-formpadding {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .rn-address {
        min-height: 220px;
    }
    .rn-address .inner h4.title {
        font-size: 22px;
    }
}


@media only screen and (max-width: 960px) and (min-width: 768px)
{
    .rn-section-contpadding {
        padding-top: 292px;
        padding-bottom: 292px;
    }
    
    .rn-section-formpadding {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .rn-address {
        min-height: 220px;
    }
    .contact-form-1 .form-group input {
        height: 55px;
    }
    .form-group {
        text-align: center;
        margin: 0 auto;
        padding-top: 20px;
    }
    .rn-address .inner h4.title {
        font-size: 20px;
    }
}


@media only screen and (max-width: 768px) and (min-width: 520px)
{
    .rn-section-contpadding {
        padding-top: 292px;
        padding-bottom: 291px;
    }
    
    .rn-section-formpadding {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .rn-address {
        min-height: 220px;
    }
    .contact-form-1 .form-group input {
        height: 55px;
    }
    .form-group {
        text-align: center;
        margin: 0 auto;
        padding-top: 20px;
    }
    .rn-address .inner h4.title {
        font-size: 20px;
    }
}


@media only screen and (max-width: 520px) and (min-width: 320px)
{
    .rn-section-contpadding {
        padding-top: 160px;
        padding-bottom: 80px;
    }
    
    .rn-section-formpadding {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .rn-address {
        min-height: 200px;
        text-align: center;
        width: 80%;
        margin: 10px auto;
    }
    .contact-form-1 .form-group input {
        height: 55px;
    }
    .form-group {
        text-align: center;
        margin: 0 auto;
        padding-top: 20px;
    }
    .rn-address .inner h4.title {
        font-size: 18px;
    }
}