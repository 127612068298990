
.mycarrierheader {
    display: none;
}

#msform fieldset:not(:first-of-type) {
display: none;
}

#heading {
      color: #FFFF01;
    font-weight: normal;
}

#msform {
    text-align: center;
    position: relative;
    margin-top: 20px;
    width: 1200px;
}

#msform fieldset {
    background:#ffffffb3;
    border: 0 none;
    border-radius: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding-bottom: 20px;
    position: relative
}

.form-card {
    text-align: left
}

#msform fieldset:not(:first-of-type) {
    display: none
}
.radio-group input {
    height: 18px !important;

}
#msform .radio-group input {
    width: 30% !important;
    margin-bottom: 30px !important;
}
#msform input,
#msform textarea {
    padding: 5px 15px 5px 15px;
    border: 1px solid #ccc;
    border-radius: 0px;
       margin-top: 2px;
       height: 42px;
    width: 100%;
    box-sizing: border-box;
    font-family: montserrat;
    color: #2C3E50;
    background-color: #fff;
    font-size: 16px;
    letter-spacing: 1px
}

#msform input:focus,
#msform textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #f30034;
    outline-width: 0
}

#msform .action-button {
    width: 100px;
    background: #000;
    font-weight: bold;
    color: #FFFF01;
    border: 0 none;
    border-radius: 0px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 20px 0px 10px 5px;
    float: right
}

#msform .action-button:hover,
#msform .action-button:focus {
    background-color: #FFFF01;
    color:#000;
}

#msform .action-button-previous {
    width: 100px;
    background: #616161;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 0px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 20px 5px 10px 0px;
    float: right
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
    background-color: #000000
}

.card {
    z-index: 0;
    border: none;
    position: relative
}

.fs-title {
    font-size: 22px;
    color: #000000;
    margin-bottom: 15px;
    font-weight: 600;
    text-align: left;
 
}
select#cars{
    padding: 5px 15px 8px 15px;
    border: 1px solid #ccc;
    border-radius: 0px;
    margin-bottom: 10px;
    margin-top: 2px;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    font-family: montserrat;
    color: #2C3E50;
    background-color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
}

.purple-text {
    color: #f30034;
    font-weight: normal
}

.steps {
    font-size: 25px;
    color: gray;
    margin-bottom: 10px;
    font-weight: normal;
    text-align: right
}
input#agreements {
    height: 20px !important;
    margin-left: -35px;
}
.fieldlabels {
    color: gray;
    text-align: left;
   margin-top: 15px;
}

#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: #242222;
    background: #d4d5d7d6;
    padding: 15px 0px;
    display: none;
}
.red.box input {
    margin-bottom: 10px !important;
}

#progressbar .active {
    color: #f30034
}
p.States {
    margin-left: -53px;
}
#msform input[type=submit] {
    transition: 0.3s;
    height: 42px;
    margin-top: 30px;
    width: 100px;
    background: #f30034;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 0px;
    cursor: pointer;
    padding: 0px 5px;
    margin: 20px 0px 10px 5px;
    float: right;
}

#progressbar li {
    list-style-type: none;
    font-size: 15px;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400
}

#progressbar #account:before {
    font-family: FontAwesome;
    content: "1"
}

#progressbar #personal:before {
    font-family: FontAwesome;
    content: "2"
}

#progressbar #payment:before {
    font-family: FontAwesome;
    content: "3"
}

#progressbar #confirm:before {
    font-family: FontAwesome;
    content: "4"
}

#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 20px;
    color: #ffffff;
    background: #615c5c;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px
}
ul#progressbar li{
    color:#615c5c;
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: -1
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: #f30034
}

#msform .progress {
    height: 20px;
    background-color: #d2d3d5;
    margin-bottom: 10px;
}

#msform .progress-bar {
    background-color: #000;
}

.fit-image {
    width: 100%;
    object-fit: cover
}

div#holderimg{
    height: 100vh;
  background-size: cover;
  background-position-x: 22%
}
div#registering img {
    max-width: 30%;
}
#logodiv {
    padding-top: 15px;
    padding-left: 25px;
}
section.carrierbnner .Hire {
    float: right;
    padding-right: 25px;
}
.forward {
    padding: 50px 100px 0px 40px;
}
p.leftt {
    margin-top: 20px;
}


@media (min-width:320px) and (max-width:767px)
{
    .mycarrierheader {
        display: unset;
    }
    div#logodiv {
        display: none;
    }
    div#holderimg {
        display: none;
    }
    .forward {
        padding: 10px;
    }
    #progressbar {
        display: none;}
        .fs-title {
     margin-bottom: 0px;
     margin-top: 10px;
        }
        #msform input, #msform textarea {
     margin-bottom: 0px;}
     p.States {
        margin-left: 0px;
       font-size: 14px;
    }
    input#agreements {
        height: 20px !important;
        margin-left: -7px;
    }
}
@media (min-width:768px) and (max-width:900px)
{
    .mycarrierheader {
        display: unset;
    }
    div#logodiv {
        display: none;
    }
    div#holderimg {
        display: none;
    }
    .forward {
        padding: 10px;
    }
    #progressbar {
        display: none;}
        .fs-title {
     margin-bottom: 30px;
     margin-top: 10px;
        }
        #msform input, #msform textarea {
     margin-bottom: 0px;}
     p.States {
        margin-left: 0px;
       font-size: 14px;
    }
    input#agreements {
        height: 20px !important;
        margin-left: -7px;
    }
}
@media (min-width:901px) and (max-width:1024px)
{
   
  
    div#holderimg {
        display: none;
    }
    .forward {
        padding: 10px;
    }
    #progressbar {
        display: none;}
        .fs-title {
     margin-bottom: 30px;
     margin-top: 10px;
        }
        #msform input, #msform textarea {
     margin-bottom: 0px;}
     p.States {
        margin-left: 0px;
       font-size: 14px;
    }
    input#agreements {
        height: 20px !important;
        margin-left: -7px;
    }
}
@media (min-width:1100px) and (max-width:1400px)
{

section.carrierbnner .Hire {
    float: right;
    padding-right: 25px;
    margin-top: 25px;
}
.forward {
    padding: 50px 50px 0px 40px;
}
}

@media only screen and (max-width: 1440px) and (min-width: 1400px)
{
    .rn-section-keypadding {
        padding-top: 40px;
        padding-bottom: 20px;
    }
    .maptitle-col.laptopvideo video {
        width: 300px;
        height: auto;
    }
}