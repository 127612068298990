.shape-image {
  display: none;
}

/*** 
====================================================================
	Banner section
====================================================================

***/

video {
  width: 100%;
  height: 99vh;
  object-fit: cover;
  object-position: 0px 10%;
}

.vidoverlaybanner {
  padding: 40px;
  position: absolute;
  top: 27%;
  left: 2%;
  width: 1600px;
}
.banright {
  text-align: right;
  padding-top: 2%;
}

section.banner {
  padding-top: 12%;
  padding-bottom: 12%;
  background-position: 85% 50%;
  background-size: cover;
  color: #fff;
  margin-top: 5%;
}

h2.bannerhead {
  font-size: 88px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 100px;
  margin-top: 30px;
  color: #fff;
  font-family: open sans !important;
  letter-spacing: -0.2px;
}
.overlaybanner {
  background-color: #fff;
  padding: 40px;
}
p.bannerpara {
  color: #fff;
  font-size: 22px;
}

h1.title {
  font-size: 124px;
  color: #000;
}

span.yel 
{
  color:#FFFF01;
  -webkit-text-fill-color:#FFFF01;
  font-weight: bold;
  font-family: open sans !important;
}









a:hover, a:focus, a:active {
text-decoration: none;
outline: none;
color: #ffffff;
}
section.blogsection {
  background: #000;
  padding-top: 10px;
}

h3 {
  font-size: 60px;
  letter-spacing: 0.1px;
  font-weight: 800;
  line-height: 1.2;
}

h4 {
  font-size: 58px;
  letter-spacing: 0.2px;
  font-weight: 800;
}

p {
 color: #2c3d52;
 letter-spacing: 1px;
}

.mobile {
  display: none;
}

.logooverflow
{
  overflow-x: hidden;
}
.fp-watermark {
  display: none;
}
.copyright-style-one.no-border {
border-top: 1px solid #1c1c1c !important;
}
.rbt-single-widget .title {
  font-size: 18px !important;
}
.rn-card.box-card-style-default h4.title {
  text-align: left !important;
}
.rn-card.box-card-style-default p {
  text-align: left !important;
}

.rn-section-topbottomsteps
  {
    padding-top: 138px;
    padding-bottom: 135px;
  }

  .rn-section-topbottommap
  {
    padding-top: 50px;
    padding-bottom: 70px;
  }

  .rn-section-topbottomlogo
  {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .rn-section-topbottomcompl
  {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .rn-section-topbottomyel
  {
    padding-top: 319px;
    padding-bottom: 318px;
  }

  .wrapper1 
  { 
    margin: 59px 0 0px 0 !important; 
  }

.rn-header.sticky {
display: none;
}
.copyright-style-one .copyright-text {
color: #e1e1e1;
}

.logo {
  width: 180px;
  height: auto;
  padding-left: 10px;
}

.header-default .logo a img {
max-height: 80px !important;
}

h3.title {
  color: #000;
  }

    
h3.titlewh {
color: #fff;
}

p.description {
  letter-spacing: 1px;
  color:#363636;
  padding-bottom: 12px;
}

p.descriptionwh {
  letter-spacing: 1px;
  color:#ccc;
  padding-bottom: 12px;
}

p.desc {
  color: #000000;
}

.container.asttop {
  padding-bottom: 20px;
}

.threebox {
  padding-top: 20px;
}

.black {
  background-color: #ffff01;
  padding: 60px 10px;
  box-shadow: 0px 10px 30px 0px;
  margin-top: 320px;
}

.rn-default-tab .tab-button .react-tabs__tab {
  padding: 20px 18px;
}

.inner p {
  color: #000;
}

.rn-footer-widget .title {
text-align: left;
}

.bg-color-blackest {
  background-color: #eee !important;
}

.rn-timeline-single.dark-line .rn-timeline .progress-dot .dot-level {
  background: #007bff;
}

.rn-timeline-single.dark-line .rn-timeline .progress-line .line-inner {
  background: #57595a !important;
}

h6.title {
  color: #000;
}
input[type="text"], input[type="password"], input[type="email"], input[type="number"], input[type="tel"], textarea {
border: 2px solid #eee !important;
}
a.btn-default, button.btn-default {
background: linear-gradient(95deg, var(--color-primary) 15%, #0097e1 45%, #0098e2 75%, #0096e0 100%) 95%/200% 100%;
}


section.har1 h1 {
  color: #FFFF01;
}

.fullcontainer {
  padding: 5px 110px;
}
.container1 {
  max-width: 1920px;
}

a.btn-default.btn-small.btn-icon {
background: #fff !important;
color: #000;
font-weight: 700;
}

video#slidervideo {
  object-fit: cover;
  height: 100vh;
}

.inner.text-right {
  position: absolute;
  bottom: 190px;
  left: 60%;
}

h1.title.display-one {
  font-family: var(--font-primary);
  margin-bottom: 25px;
  display: block;
  color: var(--color-white);
  font-weight: 500;
  font-size: 64px;
  margin-top: 0;
}

h6.tag-title {
  color: #FFFF01;
  margin: 0;
  letter-spacing: 1.5px;
  padding-left: 129px;
  position: relative;
  margin-top: 50px;
}

.inner .tag-title::before {
  position: absolute;
  content: "";
  left: 200px;
  width: 100px;
  height: 2px;
  background: #FFFF01;
  top: 50%;
  transform: translateY(-50%);
}
h6.value {
  font-size: 24px;
  line-height: 34px;
  font-style: italic;
  color:#000;
}
h2.incred {
  font-size: 96px;
  line-height: 100px;
  font-weight: 900;
  color: #000;
  padding-bottom: 10px;
}

.slider-area.slider-style-2 {
  overflow-x: hidden;
  overflow-y: hidden;
}
.slider-style-2 {
  padding-top: 298px !important;
}

.truck {
  width: 1200px;
  height: 294px;
  position: relative;
  animation: mymove 12s infinite;
}

@keyframes mymove {
  from {left: 1100px;}
  to {left: 0px;}
}

section.wh {
  background-color: #fff;
}

section.bl {
  background-color: #000;
}


.title {
  font-weight: 800 !important;
}


.block-icon > a > span.block-icon-copy {
  font-size: 20px;
  background: #f7f9f9;
  display: block;
  margin-bottom: 0px;
  min-height: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  transform: none !important;
  color: #2b363b;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
  padding: 20px 20px 0px 20px;
}

.block-icon > a > span.block-icon-copy1 {
  font-size: inherit;
  background: #f7f9f9;
  display: block;
  margin-bottom: 0px;
  min-height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  transform: none !important;
  color: #2b363b;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px 20px 30px 20px;
}

.block-icon > a {
  text-decoration: none !important;
  background: #fff;
  display: block;
  border: 1px solid #E7EDF0;
}
.block-icon > a > span:first-child {
  font-size: 48px;
  display: block;
  color: #0033a0;
  -webkit-transition: transform .3s ease,color .3s ease;
  -moz-transition: transform .3s ease,color .3s ease;
  -o-transition: transform .3s ease,color .3s ease;
  transition: transform .3s ease,color .3s ease;
}
.vpadding-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
p.american {
  color: #eee;
  font-size: 24px;
  letter-spacing: 1px;
  font-weight: 200;
}
.boxCard_solutn {
  padding: 20px 0px;
}

.boxCont.bg-color-2 {
  padding: 40px;
}

.bg-color-2 {
  background-color: #FFFF01;
  color: #ffffff;
  background-image: none;
}
p.descbold.mb--10 {
  font-size: 20px;
  color: #c9c9c9;
}
.bxHead {
  font-size: 32px;
  font-weight: bold;
}
.titleleft {
  text-align: left;
  color: #000;
}


@media (min-width: 1400px){
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 1400px;
  }
  }

/* My imagebox */        
* {
  box-sizing: border-box;
  /* CSS Transition */
  -webkit-transition: background .3s ease-in-out, border .3s ease-in-out;
  -moz-transition: background .3s ease-in-out, border .3s ease-in-out;
  -ms-transition: background .3s ease-in-out, border .3s ease-in-out;
  -o-transition: background .3s ease-in-out, border .3s ease-in-out;
  transition: background .3s ease-in-out, border .3s ease-in-out;
}

.clearfix::after {
  content: “”;
  clear: both;
  display: table;
}

h1.box {
  padding: 25px 0 25px 0;
  text-align: center;
}

a {
  text-decoration: none;
}

/* The Wrapper and container*/        
.itemWrapper {
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
}
.itemContainer {
  max-width: 1400px;
  margin: 0 auto;
}

.item {
  margin-bottom: 15px;
  padding: 0 10px;
  float: left;
  margin: 0px !important;
  width: 25%;
}

@media only screen and (max-width: 960px) {
 
  .item {
      width: 50%;
      margin-bottom: 15px;
      padding: 0 10px;
      float: left;

  }
}

@media only screen and (max-width: 550px) { 
  .itemContainer {
      max-width: 435px;
      margin: 0 auto;
      text-align: center;
  }

  .item {
      width: 100%;
      padding: 0 10px;
      float: none;
      margin: 0 auto;
      margin-bottom: 15px;
  }
}

/* Here is the item box css */        
.itemContent { 
  width: 100%; 
  border-radius: 3px; border: 2px solid  #cccccc; 
  height: 460px;
  position: relative; 

}
/* item or image container */
.itemContentItem { text-align: center; }
.itemContentItem img {max-width: 311px; width: 100%;}


.overDiv {
  z-index: 10;
  position: absolute;
  bottom: 0;
}

/* item description */
.itemContentTitle { 
  position: relative;
  width: 100%;
  background-color:  #f2f2f2;
  text-align: center;
  padding: 25px 15px;
  color: #404040;
  height: 100px;
}

.itemContentTitle h2 {
  font-size: 22px;
  font-weight: 700;
  padding-top: 40px;
  color: #000000;
}

  .itemContentTitleHover {
    color: #fff !important;
     background-color: #000;
     height: 147px;
  }


  .itemContainer .itemContentTitleHover h2 {
    color: #FFFF01 !important;
     background-color: #000;
     height: 147px;
     font-size: 24px;
  }


  .itemContainer .itemContentTitle:hover h2 {
      padding-top: 40px;
      color: #fff !important;
      font-size: 24px;
  }



  .itemContentTitle::before { 
    content:"\f02e";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    text-decoration: inherit;
    border-radius: 50%;
    padding: 10px;
    width: 62px; 
    height: 62px;
    border: 4px solid #fff;
    background-color: #000000;
    color: #FFFF01;
    display: inline-block;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
    margin: auto;
}

  .itemContentTitle:hover::before { 
      content:"\f02e";
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      text-decoration: inherit;
      border-radius: 50%;
      padding: 10px;
      width: 60px; 
      height: 60px;
      border: 4px solid #fff;
      background-color: #000;
      color: #FFFF01;
      display: inline-block;
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      top: -20px;
      margin: auto;
  }


.itemContentInfo { 
  position: relative;
  background-color:  #F2F2F2;
  text-align: left;
  color: #404040;
  overflow: hidden;
  height: 0px;
}

  .itemContentInfo p { 
      font-size: 19px;
      line-height: 1.6em;
      margin-bottom: 1.5em;
      color: #323232;
      padding: 30px 25px;
  }

.itemContentDownload {
  background-color: #f2f2f2;
  text-align: center;
  padding: 20px 15px;
  height: 100px;
}

h2.ins {
color: #000 !important;
}

h2.ins:hover {
color: #FFFF01 !important;
}

h2.ins:visited {
color: #FFFF01 !important;
}

h2.ins:focus {
color: #FFFF01 !important;
}

h2.ins:active {
color: #FFFF01 !important;
}



  .itemBtn {
     padding: 8px 20px;
      display: inline-block;
      border: 2px solid #d9d9d9;
      border-radius: 5px;
      background-color: #000000;
      color: #fff;
      font-size: 17px;
  }

  .itemBtn:hover {
      color: #FFFF01;
      background-color: #000;
      text-decoration: none;
      border: 2px solid #a5a0a0;
  }





/* left right section */
  .skew-blade .left-skew, .skew-blade .right-skew {
      background-position: center;
      background-size: cover;
      background-color: #000;
  }

  .skew-blade .left-skew img {
  
  }

  .skew-blade .skew-copy {
      box-sizing: content-box;
  }

  .btn {
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 1px solid transparent;
      white-space: nowrap;
      padding: 13px 20px;
      background-color: #FFFF01;
      color: #000;
      font-size: 18px;
      transition: background-color .5s ease-out;
      width: auto;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }

  .btn:hover {
    background-color: #000;
    color: #FFFF01;
}

  .skew-blade .left-skew, .skew-blade .right-skew {
      background-position: center;
      background-size: cover;
      background-color: #000;
  }
  .skew-blade .right-skew img {
      opacity: .6;
  }
  .skew-blade p {
      color: #fff;
  }

@media (min-width: 992px){
.skew-blade .left-skew, .skew-blade .right-skew {
  transform: skew(-30deg);
  overflow: hidden;
  background-image: none!important;
}
.skew-blade .left-skew {
  width: 61%;
  height: 600px;
  position: relative;
  margin-left: -21%;
  margin-bottom: 0;
  padding: 0 0 0 10%;
  float: left;
}
.skew-blade .left-skew img {

}
.skew-blade .left-skew .skew-copy {
  padding-left: 26%;
  padding-top: 5%;
}
.skew-blade .skew-copy {
  z-index: 10;
  position: relative;
  transform: skew(30deg);
  padding-top: 60px;
  padding-left: 180px;
  width: 480px;
}
.skew-blade .right-skew {
  width: 79%;
  height: 600px;
  position: relative;
  margin-right: -21%;
  padding: 0 0 0 5%;
  float: right;
  display: -ms-flexbox;
  display: flex;
}
.skew-blade .right-skew img {
  display: block;
  transform: skew(30deg) translateX(-50%);
  position: absolute;
  width: auto;
  height: 100%;
  left: 30%;
}

.skew-blade .right-skew .skew-copy {
  -ms-flex-item-align: end;
  align-self: flex-end;
  padding-top: 0;
  padding-bottom: 110px;
}
  }

  

  .c-resource-text-teaser {
      padding: 5px 35px;
      width: 100%;
  }
  .c-wrapper-grid__row__column {
      -ms-flex-align: stretch;
      align-items: stretch;
      border-right: 1px solid #bfbfbf;
      border-bottom: none;
  }
  .c-resource-text-teaser__resource-type {
      text-transform: uppercase;
      font-size: 14px;
      margin-bottom: 5px;
      color: #595a5b;
  }
  .c-resource-text-teaser__resource-name {
      padding-right: 10px;
  }
  .c-resource-text-teaser__resource-name {
      font-size: 18px;
      color: #000;
      margin-bottom: 10px;
  }
  a.cta, span.cta {
      font-style: normal;
      line-height: 55px;
      font-weight: 700;
      color: #000;
      text-decoration: none;
  }
  a.cta:hover, span.cta {
      color: #505050;
  }
  .c-resource-text-teaser__cta {
      font-size: 20px;
      display: inline-block;
  }

  .contentlogo {
      padding: 80px 30px 0px 30px;
  }


  
/* map section */

#mapStateName {
  position: relative;
  max-width: 1400px;
  overflow: hidden;
  margin: auto;
}
.maptitle-col {
  position: relative;
  overflow: hidden;
}
.mapPointer {
  width: calc(50% - 20px);
  float: left;
}
.mapPointer:nth-child(2n+1) {
  margin-right: 10px;
}
.mapPointer:nth-child(2n+2) {
  margin-left: 10px;
}
.statePointer {
  position: relative;
  display: block;
  transition: 0.8s ease all;
  background: #FFFF01;
  height: max-content;
  margin-bottom: 10px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 5px;
}
.statePointer:before {
  content: '';
  display: block;
  z-index: 8;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 8px;
  left: 5px;
  background-image: url("https://cdn-icons-png.flaticon.com/512/561/561176.png");
  background-size: contain;
}
.statePointer h3 {
  margin: 4px;
  margin-left: 30px;
}
.statePointer p {
  margin: 4px;
  margin-left: 30px;
  height: 0px;
  transition: 0.5s ease all;
  position: relative;
  overflow: hidden;
}
.statePointer.active {
  background: #FFFF01 !important;
  padding: 1px 4px;
  display: block;
  min-height: 35px;
  overflow: hidden;
  transform: translate(0px, 0px);
  transition: 0.5s ease all;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.35);
  z-index: 1;
}
.statePointer.active p {
  max-height: 100%;
  height: 14px;
}
.mapPointer .statePointer.active + div:not(.active) {
  transform: translate(0px, 0px);
  transition: 0.9s ease all;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  z-index: 1;
}
.mapPointer .statePointer.active + div:not(.active) + div:not(.active) {
  transform: translate(5px, 0px);
  transition: 1.2s ease all;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.stateName {
  cursor: pointer;
  fill: #f5f8fa;
}
.stateName.available {
  fill: #48bbf3;
}
.stateName:hover, .stateName.active {
  fill: #FFFF01 !important;
}
#labels, #labels_outside, #lines {
  /*   display:none; */
}
svg:hover #labels, svg:hover #labels_outside, svg:hover #lines {
  display: unset !important;
}
#labels {
  fill: #4e4e4e !important;
}
#labels:hover {
  fill: #fff !important;
}
#labels_outside {
  fill: white !important;
}
svg:hover #labels_outside {
  fill: #2720f1 !important;
}

@media (min-width: 640px) {
  .maptitle-col {
      float: none;
      text-align: center;
  }
  .stateMap {
    float: none;
    width: 60%;
    text-align: center;
    margin: 0 auto;
  }
  .maptitle-col {
    width: 100%;
  }
  .maptitle-col.laptopvideo video {
    width: 320px;
    height: auto;
}

.maptitle-col.loadvid video {
  width: 980px;
  height: auto;
}

  .statePointer h3 {
    font-size: 16px;
  }
}
@media (max-width: 640px) {
  .statePointer h3 {
    font-size: 12px;
  }
}



img.phone {
  width: 280px;
  height: auto;
}
.padding_top {
  padding-top: 60px;
}

.rn-timeline-single .rn-timeline .progress-dot {
  height: 40px;
}

.rn-timeline-single .title {
  margin-bottom: 45px;
}
.timeline-style-two {
  padding: 40px 50px 60px 50px;
}






/* steps */
.home-solutions-slider {
  padding-top: 50px;
}
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.slick-track:before, .slick-track:after {
  display: table;
  content: '';
}
.home-solutions-slider .solution {
  width: 285px;
  height: 450px;
  padding: 10px 15px 50px;
}
.slick-initialized .slick-slide {
  display: block;
}
.solution:not(article) {
  position: relative;
  padding: 25px 15px 40px;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none;
}
.home-solutions-slider .solution:not(:last-child) .solution-circle-wrapper {
  position: relative;
}
[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}
[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s;
}
[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}
[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s;
}
[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity,transform;
}
[data-aos=fade-down] {
  transform: translate3d(0,-100px,0);
}
.home-solutions-slider .solution:not(:last-child) .solution-circle-wrapper:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  height: 10px;
  width: 280px;
  background-color: #e5e5e5;
}
.home-solutions-slider .solution-circle {
  position: relative;
}
.solution-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 193px;
  height: 193px;
  border: 6px solid #e8e8e8;
  background-color: #fff;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 52px;
  transition: all .5s ease;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.04) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.solution-circle:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 10px solid #FFFF01;
}


.btn-with-arrow-right:after, .btn-with-arrow-left:before, .btn-with-arrow-down:after, .bg-contain, .solution-menu-icon, .solution-circle, .learn-more-arrow:before, .scroll-to-top, .home-markets, .benefit-icon, .resources-files .file-link:before {
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.slick-slide img {
  display: block;
}
.solution-icon {
  max-width: 90px;
  max-height: 68px;
}
.solution p {
  color: #ccc;
}
h4.solution-title {
  color: #FFFF;
  font-size: 26px;
  line-height: 34px;
}

h4.solution-title:hover {
color: #FFF;
}


/* new left right */ 

.zoomWrapper {
  border:1px dashed #B7B8BD;
  max-width: 700px;
  overflow: hidden;
  /* -- Prevent flicker -- */
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.zoomWrapper img {
  max-width: 100%;
  height: auto;
  display: block;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.zoomWrapper img:hover {
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -ms-transform: scale(1.25);
  -o-transform: scale(1.25);
  transform: scale(1.25);
}

@keyframes flash {
  0%   { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.flasher{
  animation: flash 300ms infinite;
}




/* Latest Blogs */
h1.titleblog {
  font-size: 28px;
}
.example-1 .titleblog a {
color: #000;
}

.example-3 .titleblog a {
color: #000;
}
// Variables
$regal-blue: #034378;
$san-juan: #2d4e68;
$bermuda: #FFFF01;
$white: #fff;
$black: #000;
$open-sans: 'Open Sans',
sans-serif;
// clear-fix mixin
@mixin cf {
&::before,
&::after {
  content: '';
  display: table;
}
&::after {
  clear: both;
}
}

// Base styles
.card {
background-color: unset;
border: none;
}

.card {
float: left;
padding: 0 1.7rem;
width: 33%;
.menu-content {
  @include cf;
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    display: inline-block;
  }
  a {
    color: $white;
  }
  span {
    position: absolute;
    left: 50%;
    top: 0;
    font-size: 10px;
    font-weight: 700;
    transform: translate(-50%, 0);
  }
}
.wrapper {
  background-color: $white;
  min-height: 540px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 19px 38px rgba($black, 0.3), 0 15px 12px rgba($black, 0.2);
  &:hover {
    .data {
      transform: translateY(0);
    }
  }
}
.data {
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translateY(calc(70px + 1em));
  transition: transform 0.3s;
  .contentlogo {
    padding: 20px 30px 20px 30px;
    position: relative;
    z-index: 1;
  }
  .contentlogo:hover {
    padding: 20px 30px 65px 30px;
  }
}
.author {
  font-size: 16px;
  color: #24abf7;
}
.title {
  margin-top: 10px;
}
.text {
  height: 70px;
  margin: 0;
}
.textwh {
  height: 70px;
  margin: 0;
  color:#fff;
}
input[type='checkbox'] {
  display: none;
}
input[type='checkbox']:checked + .menu-content {
  transform: translateY(-60px);
}
}

// First example styles
.example-1 {
.wrapper {
  background: url(../../images/blog1.jpg) 20% 1% / cover no-repeat;
  background-position: center center;   
}
.date {
  position: absolute;
  top: 0;
  left: 0;
  background-color: $bermuda;
  color: $white;
  padding: 0.8em;
  span {
    display: block;
    text-align: center;
  }
  .day {
    font-weight: 700;
    font-size: 24px;
    text-shadow: 2px 3px 2px rgba($black, 0.18);
  }
  .month {
    text-transform: uppercase;
  }
  .month,
  .year {
    font-size: 12px;
  }
}
.contentlogo {
  background-color: $white;
  box-shadow: 0 5px 30px 10px rgba($black, 0.3);
}
.title {
  a {
    color: lighten($black, 50%);
  }
}
.menu-button {
  position: absolute;
  z-index: 999;
  top: 16px;
  right: 16px;
  width: 25px;
  text-align: center;
  cursor: pointer;
  span {
    width: 5px;
    height: 5px;
    background-color: lighten($black, 50%);
    color: lighten($black, 50%);
    position: relative;
    display: inline-block;
    border-radius: 50%;
    &::after,
    &::before {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      background-color: currentColor;
      position: absolute;
      border-radius: 50%;
    }
    &::before {
      left: -10px;
    }
    &::after {
      right: -10px;
    }
  }
}
.menu-content {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  transition: transform 0.3s;
  transform: translateY(0);
  li {
    width: 33.333333%;
    float: left;
    background-color: $bermuda;
    height: 60px;
    position: relative;
  }
  a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
  }
  span {
    top: -10px;
  }
}
}

// Second example styles
.example-2 {
.wrapper {
  background: url(../../images/blog2.jpg) center / cover no-repeat;
  background-position: center center;   
  &:hover {
    .menu-content {
      span {
        transform: translate(-50%, -10px);
        opacity: 1;
      }
    }
  }
}
.header {
  @include cf;
  color: $white;
  padding: 1em;
  .date {
    float: left;
    font-size: 16px;
  }
}
.menu-content {
  float: right;
  li {
    margin: 0 5px;
    position: relative;
  }
  span {
    transition: all 0.3s;
    opacity: 0;
  }
}
.data {
  color: $white;
  transform: translateY(calc(70px + 4em));
}
.title {
  a {
    color: $white;
  }
}
.button {
  display: block;
  margin: 2em auto 1em;
  text-align: left;
  font-size: 12px;
  color: $white;
  line-height: 1;
  position: relative;
  font-weight: 700;
  &::after {
    content: '\2192';
    opacity: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    transition: all 0.3s;
  }
  &:hover {
    &::after {
      transform: translate(5px, -50%);
      opacity: 1;
    }
  }
}
}



//Third example styles
.example-3 {
.wrapper {
  background: url(../../images/blog3.jpg) 20% 1% / cover no-repeat;
  background-position: center center;   
}
.date {
  position: absolute;
  top: 0;
  left: 0;
  background-color: $bermuda;
  color: $white;
  padding: 0.8em;
  span {
    display: block;
    text-align: center;
  }
  .day {
    font-weight: 700;
    font-size: 24px;
    text-shadow: 2px 3px 2px rgba($black, 0.18);
  }
  .month {
    text-transform: uppercase;
  }
  .month,
  .year {
    font-size: 12px;
  }
}
.contentlogo {
  background-color: $white;
  box-shadow: 0 5px 30px 10px rgba($black, 0.3);
}
.title {
  a {
    color: lighten($black, 50%);
  }
}
.menu-button {
  position: absolute;
  z-index: 999;
  top: 16px;
  right: 16px;
  width: 25px;
  text-align: center;
  cursor: pointer;
  span {
    width: 5px;
    height: 5px;
    background-color: lighten($black, 50%);
    color: lighten($black, 50%);
    position: relative;
    display: inline-block;
    border-radius: 50%;
    &::after,
    &::before {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      background-color: currentColor;
      position: absolute;
      border-radius: 50%;
    }
    &::before {
      left: -10px;
    }
    &::after {
      right: -10px;
    }
  }
}
.menu-content {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  transition: transform 0.3s;
  transform: translateY(0);
  li {
    width: 33.333333%;
    float: left;
    background-color: $bermuda;
    height: 60px;
    position: relative;
  }
  a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
  }
  span {
    top: -10px;
  }
}
}

.footer-style-default
{
background-color: #000;
}

.footer-style-default .footer-top {
padding: 95px 0 !important;
padding-bottom: 50px !important;
}

.footer-style-default .footer-top .rn-footer-widget .title {
font-size: 22px !important;
color: #ffff01 !important;
}
.footer-style-default .footer-top .rn-footer-widget .footer-link li a {
font-size: 18px !important;
color: #c1c1c1 !important;
line-height: 40px !important;
}

.footer-style-default .footer-top .rn-footer-widget .footer-link li a:hover {
color: #ffff01 !important;
}

.footer-style-default .footer-top .rn-footer-widget .subtitle {
color: #c1c1c1 !important;
font-size: 18px !important;
line-height: 30px !important;
}

.copyright-style-one .ft-menu li a {
font-size: 15px;
}

.copyright-style-one .ft-menu li a:hover {
color: #ffff01;
}


section.yellow {
background-color: #F5E41B;
}

h3.titleyellow {
color: #000;
font-weight: 300;
text-align: center;
font-size: 74px;
text-shadow: 2px 1px black;
}




#texte-animation {
  color:white;
}
.ball { 
  position: absolute;
  top: 0;  
  left: -20px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #000; 
  margin-left: -5px;
  transition-property: left, top;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1), cubic-bezier(0.25, 0.1, 0.25, 1);
}
#texte-animation p {
  position: relative; 
  margin: 0;
  display: inline-block;
  text-align: center;
  font-size: 76px;
  line-height: 110px;
  color: #fff;
  text-shadow: 1px 1px 3px #000;
  font-weight: 600;
  outline: none;
}
.word.lit {
  color: #000;
  font-weight: 400;
  text-shadow: 2px 1px black;
}






.picture {
display: flex;
justify-content: center;
align-items: center;
margin: 0 auto;
}

h2.am {
display: inline-flex;
position: relative;
font-weight: 700;
font-size: 4.1vw;
line-height: 1.4;   
margin: 0;
padding: 0;
// text-align: center;

&::after {
  position: absolute;
  top: 0px;
  bottom: -10px;
  left: 0px;
  display: inline-block;
  overflow: hidden;
  width: 0%;
  white-space: pre;
  content: attr(data-text);
  -webkit-text-fill-color: #000;
  // -webkit-text-stroke: transparent;
  -webkit-text-stroke: 1px #000;
  will-change: width;
  // transition: width 1.5s cubic-bezier(0.86, 0, 0.07, 1);
  animation: grow 7s cubic-bezier(0.86, 0, 0.07, 1) .4s infinite alternate both;
  
}

}


span {
display: inline-block;
-webkit-text-stroke: 1px #000;
-webkit-text-fill-color: #fdfda8;
  margin: 0;
  padding: 0px 0px;
position: relative;

&::after {
  position: absolute;
  bottom: 110px;
  // bottom: -10px;
  left: 0;
  display: inline-block;
  overflow: hidden;
  width: 0%;
  white-space: pre;
  content: attr(data-text);
  // content: "A picture is worth a thousand words";
  // -webkit-text-fill-color: white;
  -webkit-text-stroke: rgba(255,255,255,0);
  will-change: width;
  // transition: width 1.5s cubic-bezier(0.86, 0, 0.07, 1) .2s;
  animation: grow 7s cubic-bezier(0.86, 0, 0.07, 1) 2.15s infinite alternate both;
  
  background:#000;
  -webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
}

@keyframes grow {
0%,10%,90%,100% {
  width: 0%;
}
50% {
  width: 100%;
}
}





.txt-outline{
text-align:center;
font-size: 10rem !important;
line-height: 1.1;
color: #fff;
-webkit-text-stroke: 4px rgba(0,0,0,0);
        text-stroke: 4px rgba(0,0,0,0);
font-weight: 900;
letter-spacing:4px;
background: -moz-linear-gradient(-45deg, #661df7 0%, #FFFF01 25%, #ef26ba 50%, #2696f2 75%, #661df7 100%) 0% center / 200% auto;
background: -webkit-linear-gradient(-45deg, #661df7 0%,#FFFF01 25%,#ef26ba 50%,#2696f2 75%,#661df7 100%) 0% center / 200% auto;
background: linear-gradient(135deg, #661df7 0%,#FFFF01 25%,#ef26ba 50%,#2696f2 75%,#661df7 100%) 0% center / 200% auto;
background-clip: text;
-webkit-background-clip: text;
-webkit-animation: outlineRun 4s linear infinite;
        animation: outlineRun 4s linear infinite;
        padding-bottom: 97px;
}
/* webkit */
@-webkit-keyframes outlineRun {
to { background-position-x: 200%; }
}
@keyframes outlineRun {
to { background-position-x: 200%; }
}






.container2 {
background: #000;
border-radius: 5px;
box-shadow: 0 6px rgba(0,0,0,0.15);
}

h4.caption-heading {
  color: #FFF;
  font-size: 18px;
  font-weight: 600;
}

h4.caption-heading:hover {
  color: #FFFF01;
}

.caption p {
color: #a1a1a1;
}
a.label.label-primary.view-all {
color: #000;
}

.label-primary {
background-color: #f5e41b;
}
.label {
display: inline;
padding: 12px 23px;
font-size: 90%;
font-weight: 700;
line-height: 1;
color: #fff;
text-align: center;
white-space: nowrap;
vertical-align: baseline;
border-radius: 0.25em;
}

/* Page Header */
.page-header {
border-bottom: 3px solid #e1e1e1;
margin-bottom: 70px;
}
.page-header h2 {
  color: #ffffff;
  font-size: 52px;
  font-weight: 600;
  letter-spacing: -0.1rem;
  margin-top: 0;
  display: block;
  }
.page-header h3 {
  color: #404954;
  font-weight: 600;
  }
.page-header .lead {
  font-size: 20px;
  font-weight: 200;
  display: inline-block;
  margin: 0;
  max-width: 85%;
  padding-bottom: 57px;
  color: #eee;
  }
.page-header .view-all {
  display: inline-block;
  float: right;
  margin-top: 8px;
  text-transform: uppercase;
  }


/* Listing Global Style */
.thumbnail {
display: inline-block;
width: 100%;
}
.thumbnail,
.thumbnail .caption {
  padding: 0;
  border: 0;
  }

/* Hide image, Use background thumbnail instead */
.thumbnail img {

  width: 100%;
  height: auto;
  }

/* Background thumbnail */
.thumbnail .placeholder {
  display: inline-block;
  width: 100%;
  padding-bottom: 30px;
  background-position: center center !important;
  background-size: cover !important;
  }

  section.footr .placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentcolor;
    opacity: 1;
    padding-bottom: 0px;
    margin-bottom: 20px;
}



@media only screen and (min-width: 768px) {

.container2 {
  padding: 0px 30px 60px;
  background:#000;
  border-radius: 5px;
  box-shadow: 0 6px rgba(0,0,0,0.15);
  }  
}


/* Tablet Portrait */
@media only screen and (max-width: 767px) {

.container2 {
  padding-left: 24px;
  padding-right: 24px;    
  }
body [class*="col"] {
  
  }  

.thumbnail .caption {
  height: 80px;
  margin-bottom: 15px;
  font-size: 0.88rem;
  }

/* Post List style */
.post-list .thumbnail {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 25px;
  }
  /* Thumbnail style */
  .post-list .thumbnail .placeholder {
    float: left;
    width: 40%;
    padding-bottom: 26.67%;
    margin-right: 5%;
    }
  /* Caption box */
  .post-list .caption {
    float: none;
    width: auto;
    }

  .page-header .lead {
    font-size: 1.25rem;
    font-weight: 200;
    display: block;
    margin: 0;
    max-width: 100%;
    padding-bottom: 15px;
    }

  .page-header .view-all {
    float: none;
    margin: 0 0 30px;
    }

}


/* Mobile Landscape */
@media only screen and (max-width: 480px) {
.caption .caption-heading {
  font-size: 16px;
  line-height: normal;
  margin-top: 0;
  }
}


@media (max-width: 667px) {
.thumbnail .caption p { display: none; }
.thumbnail .caption {
  height: 30px;
  }  
.col-xs-6:nth-child(3n+1) { 
  clear: left; 
}
}
@media (min-width: 768px) and (max-width: 991px) { 
.thumbnail .caption p { display: none; }
.caption .caption-heading { font-size: 15px; }
}



/* Footer */
.site-info { text-align: center; margin: 30px 0; }


/* Bootstrap 5 columns */
.col-xs-5bx,
.col-sm-5bx,
.col-md-5bx,
.col-lg-5bx {
min-height: 1px;
padding-right:15px;
padding-left: 15px;
position: relative;
}
@media (max-width: 767px) { .col-xs-5bx { width: 20%; float: left; } }
@media (min-width: 768px) { .col-sm-5bx { width: 20%; float: left; } }
@media (min-width: 992px) { .col-md-5bx { width: 20%; float: left; } }
@media (min-width: 1200px){ .col-lg-5bx { width: 20%; float: left; } }


/* Print */
@media print {
.thumbnail img { display: block; }
.thumbnail .placeholder { padding: 0; }

.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
  float: left;
}
.col-sm-12 {
  width: 100% !important;
}
.col-sm-11 {
  width: 91.66666667%;
}
.col-sm-10 {
  width: 83.33333333%;
}
.col-sm-9 {
  width: 75%;
}
.col-sm-8 {
  width: 66.66666667%;
}
.col-sm-7 {
  width: 58.33333333%;
}
.col-sm-6 {
  width: 50%;
}
.col-sm-5 {
  width: 41.66666667%;
}
.col-sm-4 {
  width: 33.33333333%;
}
.col-sm-3 {
  width: 25%;
}
.col-sm-2 {
  width: 16.66666667%;
}
.col-sm-1 {
  width: 8.33333333%;
}
.col-sm-pull-12 {
  right: 100%;
}
.col-sm-pull-11 {
  right: 91.66666667%;
}
.col-sm-pull-10 {
  right: 83.33333333%;
}
.col-sm-pull-9 {
  right: 75%;
}
.col-sm-pull-8 {
  right: 66.66666667%;
}
.col-sm-pull-7 {
  right: 58.33333333%;
}
.col-sm-pull-6 {
  right: 50%;
}
.col-sm-pull-5 {
  right: 41.66666667%;
}
.col-sm-pull-4 {
  right: 33.33333333%;
}
.col-sm-pull-3 {
  right: 25%;
}
.col-sm-pull-2 {
  right: 16.66666667%;
}
.col-sm-pull-1 {
  right: 8.33333333%;
}
.col-sm-pull-0 {
  right: auto;
}
.col-sm-push-12 {
  left: 100%;
}
.col-sm-push-11 {
  left: 91.66666667%;
}
.col-sm-push-10 {
  left: 83.33333333%;
}
.col-sm-push-9 {
  left: 75%;
}
.col-sm-push-8 {
  left: 66.66666667%;
}
.col-sm-push-7 {
  left: 58.33333333%;
}
.col-sm-push-6 {
  left: 50%;
}
.col-sm-push-5 {
  left: 41.66666667%;
}
.col-sm-push-4 {
  left: 33.33333333%;
}
.col-sm-push-3 {
  left: 25%;
}
.col-sm-push-2 {
  left: 16.66666667%;
}
.col-sm-push-1 {
  left: 8.33333333%;
}
.col-sm-push-0 {
  left: auto;
}
.col-sm-offset-12 {
  margin-left: 100%;
}
.col-sm-offset-11 {
  margin-left: 91.66666667%;
}
.col-sm-offset-10 {
  margin-left: 83.33333333%;
}
.col-sm-offset-9 {
  margin-left: 75%;
}
.col-sm-offset-8 {
  margin-left: 66.66666667%;
}
.col-sm-offset-7 {
  margin-left: 58.33333333%;
}
.col-sm-offset-6 {
  margin-left: 50%;
}
.col-sm-offset-5 {
  margin-left: 41.66666667%;
}
.col-sm-offset-4 {
  margin-left: 33.33333333%;
}
.col-sm-offset-3 {
  margin-left: 25%;
}
.col-sm-offset-2 {
  margin-left: 16.66666667%;
}
.col-sm-offset-1 {
  margin-left: 8.33333333%;
}
.col-sm-offset-0 {
  margin-left: 0%;
}
.visible-xs {
  display: none !important;
}
.hidden-xs {
  display: block !important;
}
table.hidden-xs {
  display: table;
}
tr.hidden-xs {
  display: table-row !important;
}
th.hidden-xs,
td.hidden-xs {
  display: table-cell !important;
}
.hidden-xs.hidden-print {
  display: none !important;
}
.hidden-sm {
  display: none !important;
}
.visible-sm {
  display: block !important;
}
table.visible-sm {
  display: table;
}
tr.visible-sm {
  display: table-row !important;
}
th.visible-sm,
td.visible-sm {
  display: table-cell !important;
}
}


/* map*/





/* Start Responsiveness */

@media only screen and (max-width: 1440px) and (min-width: 1400px)
{
  .mainmenu-nav .mainmenu > li > a {
    font-size: 16px;
}
  h2.bannerhead {
    font-size: 76px;
    line-height: 86px;
}
  .slider-style-2 {
    padding-top: 238px !important;
}
.truck {
  width: 1100px;
  height: 266px;
}
.txt-outline {
  font-size: 9rem !important;
  padding-bottom: 47px;
}
  .fullcontainer {
   padding: 5px 30px;
}
   .header-right {
    padding-right: 20px;
}
.maptitle-col.loadvid video {
  width: 680px;
  height: auto;
}
.skew-blade .left-skew .skew-copy {
  padding-left: 20%;
  padding-top: 9%;
}
img.skewlogo {
  width: 330px;
  height: auto;
}
.itemWrapper {
  padding-top: 45px;
}
.solution-circle {
  width: 145px;
  height: 145px;
  margin-bottom: 35px;
}
.home-solutions-slider {
  padding-top: 40px;
}
.container.asttop {
  padding-bottom: 0px;
}


.rn-section-topbottomsteps
  {
    padding-top: 65px;
    padding-bottom: 65px;
  }
  .rn-section-topbottommap
  {
    padding-top: 60px;
    padding-bottom: 70px;
  }
  .stateMap {
    width: 50%;
}
  .rn-section-topbottomlogo
  {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .rn-section-topbottomcompl
  {
    padding-top: 70px;
    padding-bottom: 110px;
  }

  .rn-section-topbottomyel
  {
    padding-top: 280px;
    padding-bottom: 280px;
  }

  .wrapper1 
  { 
    margin: 46px 0 0px 0 !important; 
  }


h3 {
  font-size: 52px !important;
}
h4 {
  font-size: 48px;
}

h2.am {
  font-size: 5.1vw;
}
span::after {
  bottom: 103px;
}
.itemContentTitle::before {
  top: -36px;
}
.itemContentTitle h2 {
  font-size: 20px;
}
.itemContentTitle {
  padding: 10px 15px;
}
.itemContentTitle h2 {
  padding-top: 55px;
}
.home-solutions-slider .solution {
  width: 275px;
}
.footer-style-default .footer-top {
  padding: 50px 0 10px 0 !important;
  padding-bottom: 10px !important;
}

.footer-style-default .footer-top .rn-footer-widget .footer-link li {
  margin-top: 2px;
  margin-bottom: 2px;
}
.page-header {
  margin-bottom: 52px;
}
.page-header .lead {
  font-size: 18px;
  padding-bottom: 35px;
}
.page-header .view-all {
  margin-top: -6px;
}
.copyright-style-one {
  padding: 18px 0 20px 0;
}
.footer-style-default .footer-top .rn-footer-widget .footer-link li a {
  font-size: 17px !important;
  line-height: 37px !important;
}
}


@media only screen and (max-width: 1400px) and (min-width: 1200px)
{
  h2.bannerhead {
    font-size: 72px;
    line-height: 82px;
}
  .slider-style-2 {
    padding-top: 238px !important;
}
.truck {
  width: 1100px;
  height: 266px;
}
.txt-outline {
  font-size: 9rem !important;
  padding-bottom: 47px;
}
  .fullcontainer {
   padding: 5px 30px;
}
   .header-right {
    padding-right: 20px;
}
.maptitle-col.loadvid video {
  width: 680px;
  height: auto;
}
.skew-blade .left-skew .skew-copy {
  padding-left: 20%;
  padding-top: 9%;
}
img.skewlogo {
  width: 330px;
  height: auto;
}
.itemWrapper {
  padding-top: 45px;
}
.solution-circle {
  width: 145px;
  height: 145px;
  margin-bottom: 35px;
}
.home-solutions-slider {
  padding-top: 15px;
}
.container.asttop {
  padding-bottom: 0px;
}
.container {
  padding-left: 40px;
  padding-right: 40px;
}

.rn-section-topbottomsteps
  {
    padding-top: 80px;
    padding-bottom: 70px;
  }

  .rn-section-topbottommap
  {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .rn-section-topbottomlogo
  {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .rn-section-topbottomcompl
  {
    padding-top: 70px;
    padding-bottom: 110px;
  }

  .rn-section-topbottomyel
  {
    padding-top: 280px;
    padding-bottom: 280px;
  }

  .wrapper1 
  { 
    margin: 46px 0 0px 0 !important; 
  }


h3 {
  font-size: 52px !important;
}
h4 {
  font-size: 48px;
}

h2.am {
  font-size: 5.1vw;
}
span::after {
  bottom: 103px;
}
.itemContentTitle::before {
  top: -36px;
}
.itemContentTitle h2 {
  font-size: 20px;
}
.itemContentTitle {
  padding: 10px 15px;
}
.itemContentTitle h2 {
  padding-top: 55px;
}
.home-solutions-slider .solution {
  width: 275px;
}
.footer-style-default .footer-top {
  padding: 50px 0 10px 0 !important;
  padding-bottom: 10px !important;
}

.footer-style-default .footer-top .rn-footer-widget .footer-link li {
  margin-top: 2px;
  margin-bottom: 2px;
}
.page-header {
  margin-bottom: 52px;
}
.page-header .lead {
  font-size: 18px;
  padding-bottom: 35px;
}
.page-header .view-all {
  margin-top: -6px;
}
.copyright-style-one {
  padding: 18px 0 20px 0;
}
.footer-style-default .footer-top .rn-footer-widget .footer-link li a {
  font-size: 17px !important;
  line-height: 37px !important;
}
}


@media only screen and (max-width: 1200px) and (min-width: 1024px)
{
  h2.bannerhead {
    font-size: 68px;
    line-height: 78px;
}
.fullcontainer {
    padding: 5px 10px;
 }
 .header-right {
     padding-right: 10px;
 }
 .header-default .header-right {
  justify-content: start;
}
.mainmenu-nav .mainmenu > li > a {
  font-size: 15px;
}
.header-default .logo a img {
  max-height: 65px !important;
}
.logo {
  width: 160px;
  text-align: center;
}
.slider-style-2 {
  padding-top: 281px !important;
}
.truck {
  width: 900px;
  height: 212px;
}
.txt-outline {
  font-size: 8rem !important;
  padding-bottom: 80px;
}
.maptitle-col.loadvid video {
  width: 480px;
  height: auto;
}
.skew-blade .left-skew .skew-copy {
  padding-left: 16%;
  padding-top: 13%;
}
img.skewlogo {
  width: 290px;
  height: auto;
}


.rn-section-topbottomsteps
  {
    padding-top: 68px;
    padding-bottom: 68px;
  }

  .rn-section-topbottommap
  {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .rn-section-topbottomlogo
  {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .rn-section-topbottomcompl
  {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .rn-section-topbottomyel
  {
    padding-top: 290px;
    padding-bottom: 290px;
  }

  .wrapper1 
  { 
    margin: 46px 0 0px 0 !important; 
  }


h3 {
  font-size: 46px !important;
}
h4 {
  font-size: 42px;
}
.skew-blade .skew-copy {
  padding-left: 125px;
}
.skew-blade .right-skew .skew-copy {
  padding-bottom: 97px;
}
span::after {
  bottom: 85px;
}
.itemContentItem img {
  max-width: 240px;
}
.itemContentTitle::before {
  top: -36px;
}
.itemContentTitle h2 {
  font-size: 20px;
}
.itemContentTitle {
  padding: 10px 15px;
}
.itemContentInfo p {
  font-size: 17px;
  padding: 30px 15px;
  height: 94px;
}
.itemContainer .itemContentTitle:hover h2 {
  font-size: 20px;
}
.itemContainer .itemContentTitleHover h2 {
  font-size: 20px;
}
.home-solutions-slider .solution {
  width: 220px;
}
.solution-circle {
  width: 145px;
  height: 145px;
  margin-bottom: 25px;
}
.home-solutions-slider {
  padding-top: 15px;
}
.container.asttop {
  padding-bottom: 0px;
}
.footer-style-default .footer-top {
  padding: 55px 0 10px 0 !important;
  padding-bottom: 10px !important;
}
}


@media only screen and (max-width: 1024px) and (min-width: 960px)
{
  h2.bannerhead {
    font-size: 62px;
    line-height: 72px;
}
  .fullcontainer {
    padding: 5px 10px;
 }
 .mainmenu-nav .mainmenu > li > a {
  font-size: 14px;
  padding: 0 15px;
}
.header-default .header-right .header-btn {
  margin-left: 0px;
}
a.btn-default.btn-small.btn-icon {
    background: #000;
    color: #FFFF01;
}
a.btn-default.btn-small, button.btn-default.btn-small {
    padding: 0 10px;
}
.slider-style-2 {
  padding-top: 310px !important;
}
.truck {
  width: 800px;
  height: 185px;
}
.txt-outline {
  font-size: 7rem !important;
  padding-bottom: 100px;
}
.maptitle-col.loadvid video {
  width: 480px;
  height: auto;
}
img.skewlogo {
  width: 290px;
  height: auto;
}

h3 {
  font-size: 46px !important;
}
h4 {
  font-size: 42px;
}
.skew-blade .left-skew .skew-copy {
  padding-left: 9%;
  padding-top: 15%;
}
.skew-blade .skew-copy {
  padding-left: 100px;
}
.skew-blade .right-skew .skew-copy {
  padding-bottom: 105px;
}

.rn-section-topbottomsteps
  {
    padding-top: 92px;
    padding-bottom: 92px;
  }

  .rn-section-topbottommap
  {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .rn-section-topbottomlogo
  {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .rn-section-topbottomcompl
  {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .rn-section-topbottomyel
  {
    padding-top: 300px;
    padding-bottom: 300px;
  }

  .wrapper1 
  { 
    margin: 35px 0 0px 0 !important; 
  }

.page-header h2 {
  font-size: 48px;
}
.page-header .lead {
  font-size: 16px;
  max-width: 70%;
}
.page-header {
  margin-bottom: 50px;
}
.page-header .lead {
  padding-bottom: 35px;
}
.footer-style-default .footer-top .rn-footer-widget .footer-link li a {
  font-size: 16px !important;
  line-height: 36px !important;
}
.footer-style-default .footer-top .rn-footer-widget .subtitle {
  font-size: 16px !important;
  line-height: 26px !important;
}
.footer-style-default .footer-top .rn-footer-widget .title {
  font-size: 20px !important;
}
.footer-style-default .footer-top {
  padding: 70px 0 10px 0 !important;
  padding-bottom: 10px !important;
}
.copyright-style-one {
  padding: 16px 0;
}
h2.am {
  font-size: 5.1vw;
}
span::after {
  bottom: 73px;
}
.itemContentItem img {
  max-width: 441px;
}
.itemContent {
  margin-bottom: 40px;
  height: 400px;
}
.itemContentTitle::before {
  top: -30px;
}
.itemContentTitle::before {
  font-size: 16px;
  width: 52px;
  height: 52px;
}
.itemContentTitle h2 {
  font-size: 20px;
  padding-top: 25px;
}
.itemContentTitle {
  padding: 10px 15px;
  height: 100px;
}
h4.solution-title {
  font-size: 22px;
}
.home-solutions-slider .solution {
  width: 193px;
}
.solution-circle {
  width: 130px;
  height: 130px;
  margin-bottom: 20px;
}
.home-solutions-slider {
  padding-top: 15px;
}
.container.asttop {
  padding-bottom: 15px;
}
.home-solutions-slider .solution:not(:last-child) .solution-circle-wrapper:before {
  width: 250px;
}
h4.caption-heading {
  font-size: 16px;
}
h6.subtitle {
  text-align: left;
}
ul.footer-link li {
  text-align: left;
}  
}


@media only screen and (max-width: 960px) and (min-width: 768px)
{
  h2.bannerhead {
    font-size: 58px;
    line-height: 68px;
}
.vidoverlaybanner {
  left: -8%;
}
  .fullcontainer {
    padding: 5px 30px;
 }
 .header-default .logo a img {
  max-height: 70px !important;
}
.slider-style-2 {
  padding-top: 320px !important;
}
.truck {
  width: 800px;
  height: 185px;
}
.txt-outline {
  font-size: 7rem !important;
  padding-bottom: 90px;
}
.maptitle-col.loadvid video {
  width: 480px;
  height: auto;
}
.page-header h2 {
  font-size: 44px;
}
.page-header .lead {
  font-size: 18px;
  max-width: 70%;
}
.skew-blade .left-skew .skew-copy {
  padding-left: 0;
  padding-top: 0;
}
img.skewlogo {
  width: 280px;
  height: auto;
}
.contentlogo {
  padding: 50px 20px;
  text-align: center;
}
.skew-blade .right-skew .skew-copy {
  position: relative;
  margin-top: -28%;
  text-align: center;
}
.skew-blade .right-skew img {
  height: 340px;
  width: auto;
}
.skew-blade .right-skew .skew-copy {
  padding-bottom: 47px;
  padding-left: 40px;
  padding-right: 40px;
}
.skew-blade .skew-copy {
  padding-left: 0px;
}


.rn-section-topbottomsteps
  {
    padding-top: 90px;
    padding-bottom: 40px;
  }

  .rn-section-topbottommap
  {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .rn-section-topbottomlogo
  {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .rn-section-topbottomcompl
  {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .rn-section-topbottomyel
  {
    padding-top: 304px;
    padding-bottom: 304px;
  }

  .wrapper1 
  { 
    margin: 50px 0 0px 0 !important; 
  }


h3 {
  font-size: 42px !important;
}
h4 {
  font-size: 38px;
}
h2.am {
  font-size: 4.2vw;
}
span::after {
  bottom: 56px;
}
.slick-track {
  display: initial;
}
.home-solutions-slider .solution:not(:last-child) .solution-circle-wrapper:before
{
  display: none;
}
.home-solutions-slider .solution {
  width: 340px;
}
.itemContentTitle::before {
  top: -40px;
}
.itemContentTitle {
  padding: 10px 15px;
  height: 83px;
}
.itemContainer .itemContentTitle:hover h2 {
  padding-top: 35px;
  font-size: 20px;
}
.itemContainer .itemContentTitleHover h2 {
  font-size: 20px;
}
.itemContentTitle h2 {
  padding-top: 18px;
}
.itemContentInfo p {
  font-size: 18px;
}
.footer-style-default .footer-top .rn-footer-widget .footer-link li {
  margin-top: 2px;
  margin-bottom: 2px;
}
.copyright-style-one {
  padding: 12px 0;
}
h6.subtitle {
  text-align: left;
}
ul.footer-link li {
  text-align: left;
} 
}


@media only screen and (max-width: 768px) and (min-width: 520px)
{
  h2.bannerhead {
    font-size: 54px;
    line-height: 64px;
}
.vidoverlaybanner {
  left: 0%;
}
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .aimsmob {
    padding: 0px 20px;
  }
  .aimsmob span {
    -webkit-text-stroke: transparent;
    -webkit-text-fill-color: #000000;
  }
  .skew-copy1 {
    padding: 70px 50px;
    text-align: center;
  }
  .fullcontainer {
    padding: 5px 30px;
 }
 .header-default .logo a img {
  max-height: 60px !important;
}
.header-default 
{
border-bottom: 1px solid hsl(0deg 0% 50% / 15%);
}
.slider-style-2 {
  padding-top: 387px !important;
}
.truck {
  width: 600px;
  height: 130px;
}
.txt-outline {
  font-size: 6rem !important;
  padding-bottom: 100px;
}
.maptitle-col.loadvid video {
  width: 380px;
  height: auto;
}
.page-header h2 {
  font-size: 38px;
}
.page-header .lead {
  font-size: 18px;
  max-width: 70%;
}
.footer-style-default .footer-top {
  padding: 50px 0 !important;
  padding-bottom: 45px !important;
}
.skew-blade .left-skew .skew-copy {
  padding-left: 0;
  padding-top: 0;
}
img.skewlogo {
  width: 300px;
  height: auto;
}
.contentlogo {
  padding: 50px 20px;
  text-align: center;
}
.skew-blade .right-skew .skew-copy {
  position: relative;
  margin-top: -42%;
  text-align: center;
}
.skew-blade .right-skew img {
  height: 370px;
  width: auto;
}
.skew-blade .right-skew .skew-copy {
  padding-bottom: 55px;
  padding-left: 40px;
  padding-right: 40px;
}
.skew-blade .skew-copy {
  padding-left: 0px;
}

.rn-section-topbottomsteps
  {
    padding-top: 90px;
    padding-bottom: 80px;
  }

  .rn-section-topbottommap
  {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .rn-section-topbottomlogo
  {
    padding-top: 30px;
    padding-bottom: 25px;
  }

  .rn-section-topbottomcompl
  {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .rn-section-topbottomyel
  {
    padding-top: 310px;
    padding-bottom: 310px;
  }

  .wrapper1 
  { 
    margin: 50px 0 0px 0 !important; 
  }


h3 {
  font-size: 38px !important;
  line-height: 1.1;
}
h4 {
  font-size: 34px;
}
h2.am {
  font-size: 6.5vw;
}
span::after {
  bottom: 51px;
}
.itemContentTitle::before {
  top: -40px;
}
.itemContentTitle h2 {
  padding-top: 20px;
}
.itemContent {
  margin-bottom: 40px;
}
.slick-track {
  display: initial;
}
.home-solutions-slider .solution:not(:last-child) .solution-circle-wrapper:before
{
  display: none;
}
.home-solutions-slider .solution {
  width: 340px;
}
.page-header {
  margin-bottom: 30px;
}
.page-header .lead {
  padding-bottom: 25px;
}
.footer-style-default .footer-top .rn-footer-widget .footer-link li a {
  line-height: 26px !important;
}
.itemContent {
  height: 400px;
}
h6.subtitle {
  text-align: left;
}
ul.footer-link li {
  text-align: left;
} 

}


@media only screen and (max-width: 520px) and (min-width: 425px)
{
  h2.bannerhead {
    font-size: 40px;
    line-height: 50px;
    width: 30%;
}
.vidoverlaybanner {
  left: 0%;
}
video {
  width: 100%;
  height: 59vh;
  object-fit: cover;
  object-position: none;
}

.section.secmobile {
    display: block;
    height: 486px;
}
p {
font-size: 16px !important;
}
.desktop {
  display: none;
}
.mobile {
  display: block;
}
.aimsmob {
  padding: 0px 25px;
}
.aimsmob span {
  -webkit-text-stroke: transparent;
  -webkit-text-fill-color: #000000;
}
.rn-card.card-list-view .inner .content {
  flex-basis: 90%;
}
  .fullcontainer {
    padding: 5px 10px;
 }
 .header-default .logo a img {
  max-height: 60px !important;
}
.header-default 
{
border-bottom: 1px solid hsl(0deg 0% 50% / 15%);
}
.maptitle-col.loadvid video {
  width: 280px;
  height: auto;
}
.maptitle-col {
  padding-top: 40px;
  text-align: center;
}
.slider-style-2 {
  padding-top: 220px !important;
}
.txt-outline {
  font-size: 38px !important;
  padding-bottom: 60px;
}
.truck {
  width: 380px;
  height: 82px;
}
#mapStateName {
  max-width: 350px;
  padding-top: 0px;
}
.page-header h2 {
  font-size: 32px;
}
.page-header .lead {
  font-size: 16px;
}
.rn-footer-widget {
  padding-left: 15px;
}
.footer-style-default .footer-top .rn-footer-widget .footer-link li a {
  font-size: 16px !important;
  line-height: 30px !important;
}
.footer-style-default .footer-top {
  padding: 40px 0 !important;
}
.footer-style-default .footer-top .rn-footer-widget .subtitle {
  font-size: 16px !important;
}
.copyright-style-one .copyright-text {
  padding-top: 10px;
}
.rn-back-top {
  bottom: 10px;
  width: 45px;
  height: 45px;
  line-height: 42px;
}
.label {
  font-size: 80%;
}
.skew-blade .left-skew .skew-copy {
  padding-left: 0;
  padding-top: 0;
}
img.skewlogo {
  width: 200px;
}
.skew-copy1 {
  padding: 70px 30px;
  text-align: center;
}
.contentlogo {
  padding: 50px 20px;
  text-align: center;
}
.skew-blade .right-skew .skew-copy {
  position: relative;
  margin-top: -74%;
  text-align: center;
}
.skew-blade .right-skew img {
  height: 440px;
}
.skew-blade .right-skew .skew-copy {
  padding-bottom: 110px;
  padding-left: 15px;
  padding-right: 15px;
}
.skew-blade .skew-copy {
  padding-left: 0px;
}
.btn {
  white-space: unset;
  padding: 13px 20px;
  font-size: 16px;
}
.itemContentItem img {
  max-width: 456px;
  width: 100%;
}
.item {
  margin: 20px 0px !important;
}

h3 {
  font-size: 32px !important;
  letter-spacing: 0.1px;
  line-height: 1.2 !important;
}

h4 {
  font-size: 28px;
  letter-spacing: 0.2px;
  font-weight: 800;
}

h2.am {
  font-size: 5.6vw;
}

.asttop {
  padding-bottom: 0px;
}
.itemWrapper {
  padding-top: 0px;
}

.rn-section-topbottomsteps
  {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .rn-section-topbottommap
  {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .rn-section-topbottomlogo
  {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .rn-section-topbottomcompl
  {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .rn-section-topbottomyel
  {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .wrapper1 
  { 
    margin: 46px 20px 5px 20px !important; 
  }

.rn-section-gaptopbottomabt 
{
    padding-top: 100px !important;
    padding-bottom: 100px !important;
}

.slick-track {
  display: initial;
}
.home-solutions-slider .solution:not(:last-child) .solution-circle-wrapper:before
{
  display: none;
}
.home-solutions-slider .solution {
  width: 100%;
}
h6.subtitle {
  text-align: left;
}
ul.footer-link li {
  text-align: left;
} 
}


@media only screen and (max-width: 425px) and (min-width: 320px)
{
  h2.bannerhead {
    font-size: 32px;
    line-height: 43px;
    width: 16%;
}
.vidoverlaybanner {
  padding: 15px;
  left: 0%;
}
video {
  width: 100%;
  height: 59vh;
  object-fit: cover;
  object-position: none;
}
  .section.secmobile {
    display: block;
    height: 486px;
}
p {
  font-size: 16px !important;
  }

.desktop {
  display: none;
}
.mobile {
  display: block;
}
.aimsmob {
  padding: 0px 20px;
}
.aimsmob span {
  -webkit-text-stroke: transparent;
  -webkit-text-fill-color: #000000;
}
.rn-card.card-list-view .inner .content {
  flex-basis: 100%;
}
  .fullcontainer {
    padding: 5px 10px;
 }
 .header-default .logo a img {
  max-height: 60px !important;
}
.header-default 
{
border-bottom: 1px solid hsl(0deg 0% 50% / 15%);
}
.maptitle-col.loadvid video {
  width: 280px;
  height: auto;
}
.maptitle-col {
  padding-top: 40px;
  text-align: center;
}
.slider-style-2 {
  padding-top: 220px !important;
}
.txt-outline {
  font-size: 38px !important;
  padding-bottom: 60px;
}
.truck {
  width: 380px;
  height: 82px;
}
#mapStateName {
  max-width: 350px;
  padding-top: 0px;
}
.page-header h2 {
  font-size: 32px;
}
.page-header .lead {
  font-size: 16px;
}
.rn-footer-widget {
  padding-left: 15px;
}
.footer-style-default .footer-top .rn-footer-widget .footer-link li a {
  font-size: 16px !important;
  line-height: 30px !important;
}
.footer-style-default .footer-top {
  padding: 40px 0 !important;
}
.footer-style-default .footer-top .rn-footer-widget .subtitle {
  font-size: 16px !important;
}
.copyright-style-one .copyright-text {
  padding-top: 10px;
}
.rn-back-top {
  bottom: 10px;
  width: 45px;
  height: 45px;
  line-height: 42px;
}
.label {
  font-size: 80%;
}
.skew-blade .left-skew .skew-copy {
  padding-left: 0;
  padding-top: 0;
}
img.skewlogo {
  width: 180px;
}
.contentlogo {
  padding: 50px 20px;
  text-align: center;
}
.skew-blade .right-skew .skew-copy {
  position: relative;
  margin-top: -135%;
  text-align: center;
}
.skew-blade .right-skew img {
  height: 440px;
}
.skew-blade .right-skew .skew-copy {
  padding-bottom: 72px;
  padding-left: 15px;
  padding-right: 15px;
}
.skew-blade .skew-copy {
  padding-left: 0px;
}
.skew-copy1 {
  padding: 70px 30px;
  text-align: center;
}
.btn {
  white-space: unset;
  padding: 13px 20px;
  font-size: 16px;
}
.itemContentItem img {
  max-width: 456px;
  width: 100%;
}
.item {
  margin: 20px 0px !important;
}

h3 {
  font-size: 32px !important;
  letter-spacing: 0.1px;
  line-height: 1.2 !important;
}

h4 {
  font-size: 28px;
  letter-spacing: 0.2px;
  font-weight: 800;
}

h2.am {
  font-size: 7.0vw;
}

.asttop {
  padding-bottom: 0px;
}
.itemWrapper {
  padding-top: 0px;
}

.rn-section-topbottomsteps
  {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .rn-section-topbottommap
  {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .rn-section-topbottomlogo
  {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .rn-section-topbottomcompl
  {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .rn-section-topbottomyel
  {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .wrapper1 
  { 
    margin: 46px 20px 5px 20px !important; 
  }

.rn-section-gaptopbottomabt 
{
    padding-top: 100px !important;
    padding-bottom: 100px !important;
}

.slick-track {
  display: initial;
}
.home-solutions-slider .solution:not(:last-child) .solution-circle-wrapper:before
{
  display: none;
}
.home-solutions-slider .solution {
  width: 100%;
}
h6.subtitle {
  text-align: left;
}
ul.footer-link li {
  text-align: left;
} 
}


/* End Responsiveness */




