section.thankyou {
    background-color: #000;
    padding-top: 203px;
    text-align: center;
    padding-bottom: 150px;
}
.thankyoubox h3 {
    color: #fff;
    font-family: inherit;
    margin-top: 30px;
}
.thankyoubox img {
    width: 15%;
}
p.updates {
    color: #a79f9f;
}
.thankyoubox h5 {
    color: #fff;
    font-family: inherit;
    margin-top: 30px;
}
.thankyoubox hr {
    width: 65%;
    margin: 0 auto;
}

p.updates a {
    color: #FFFF01;
}


@media (min-width:320px) and (max-width:580px)
{
    section.thankyou {
        background-color: #000;
        padding-top: 100px;
        text-align: left;
        padding-bottom: 50px;
    }
}