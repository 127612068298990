
h1.title.theme-gradient.display-two span
{
display: inline-block;
-webkit-text-stroke: 1px #000;
-webkit-text-fill-color: #ffff01;
  margin: 0;
  padding: 0px 0px;
position: relative;
}


.button1 {
    color: #000;
    background: #ffff01;
    border-radius: 8px;
    transition: 0.2s ease-in-out;
    padding: 20px 40px;
    margin-right: 20px;
    font-size: 22px;
}

input.form-col {
    background: #fff;
    height: 64px;
}


input.btn-city-submit {
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    border-radius: 8px;
    background-color: transparent;
    background: #000 !important;
    transition-duration: 300ms;
    color: #ffff01;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    text-shadow: var(--shadow-light);
    display: inline-block;
    letter-spacing: 0.5px;
    height: 60px;
    line-height: 56px;
    padding: 0 37px;
    font-size: 18px;
    width: 100%;
    margin-top: 5px;
}

h4.client {
    font-size: 26px;
    color: #ffff01;
}

.clienttop
{
    margin-top: 40px;
}


.key-service ul li {
    padding: 10px;
    border-bottom: 1px solid #e3e307;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3EArrow Forward%3C/title%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M268 112l144 144-144 144M392 256H100'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 32px;
    background-position: 0;
    padding-left: 60px;
    list-style: none;
    color: #585858;
    font-size: 20px;
}

.key-service ul li span {
    display: inline-block;
    -webkit-text-stroke: 1px #000;
    -webkit-text-fill-color: #000000;
    margin: 0;
    padding: 12px 0px;
    position: relative;
}

.list-icon li {
    color: #000;
    font-weight: 100;
}

.list-icon li .icon {
    background-color: #ffff;
}

.slider-style-6.shape-right .shape-image {
    text-align: right;
}
.height-920 {
    height: 968px;
    display: flex;
    align-items: center;
}
a.cali.btn-default.btn-medium.btn-icon.btn-border {
    background-color: #ffff01 !important;
    color: #000;
}

.stateMap1 {
    float: right;
    width: 100%;
}
.citymap {
    z-index: 1;
}

img.tab {
    width: 700px;
    height: auto;
}

h5.subtitle {
    color: #000;
    font-size: 22px;
    background-color: #ffff01;
    padding: 4px;
    width: 700px;
    text-align: center;
    margin: 30px auto;
}

.rn-section-benefits
{
    padding-top: 230px;
    padding-bottom: 229px;
}

.rn-section-ctaform
{
    padding-top: 273px;
    padding-bottom: 274px;
}






/* Start Responsiveness */

@media only screen and (max-width: 1440px) and (min-width: 1200px)
{
    .height-920 {
        height: 807px;
    }
    .key-service ul li {
        font-size: 18px;
    }
    .stateMap1 {
        width: 85%;
    }
    
    .rn-section-benefits
    {
        padding-top: 152px;
        padding-bottom: 152px;
    }
    
    .rn-section-keypadding {
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .rn-section-ctaform
    {
        padding-top: 195px;
        padding-bottom: 192px;
    }
}



@media only screen and (max-width: 1200px) and (min-width: 1024px)
{
    .height-920 {
        height: 807px;
    }
    .key-service ul li {
        font-size: 18px;
        padding: 8px 60px;
    }
    .rn-section-benefits
    {
        padding-top: 140px;
        padding-bottom: 140px;
    }
    
    .rn-section-keypadding {
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .rn-section-ctaform
    {
        padding-top: 194px;
        padding-bottom: 194px;
    }
    
}


@media only screen and (max-width: 1024px) and (min-width: 960px)
{
    .height-920 {
        height: 807px;
    }
    .key-service ul li {
        font-size: 18px;
        padding: 8px 60px;
    }
    .rn-section-benefits
    {
        padding-top: 125px;
        padding-bottom: 125px;
    }
    
    .rn-section-keypadding {
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .rn-section-ctaform
    {
        padding-top: 180px;
        padding-bottom: 180px;
    }
}


@media only screen and (max-width: 960px) and (min-width: 768px)
{    
    .height-920 {
    height: 807px;
    }
    .stateMap1 {
        width: 80%;
        float: none;
        text-align: center;
        margin: 0 auto;
    }
    .inner {
        text-align: center;
    }
    .key-service ul li {
        font-size: 18px;
        padding: 8px 60px;
    }
    .buttn {
        margin-top: 30px;
    }
    input.form-col {
        height: 54px;
    }
    .rn-section-benefits
    {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    
    .rn-section-keypadding {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .rn-section-ctaform
    {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}


@media only screen and (max-width: 768px) and (min-width: 520px)
{
    .height-920 {
        height: 807px;
    }
    .stateMap1 {
        width: 75%;
        float: none;
        text-align: center;
        margin: 0 auto;
    }
    .inner {
        text-align: center;
    }
    .key-service ul li {
        font-size: 18px;
        padding: 8px 60px;
    }
    .buttn {
        margin-top: 30px;
    }
    input.form-col {
        height: 54px;
    }
    .rn-section-benefits
    {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    
    .rn-section-keypadding {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .rn-section-ctaform
    {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}


@media only screen and (max-width: 520px) and (min-width: 425px)
{
    .height-920 {
        height: 807px;
    }
    .stateMap1 {
        width: 75%;
        float: none;
        text-align: center;
        margin: 0 auto;
    }
    .inner {
        text-align: center;
    }
    .key-service ul li {
        font-size: 16px;
        padding: 8px 0px;
        background-image: none;
    }
    h5.subtitle {
        font-size: 20px;
        padding: 15px;
        width: 320px;
    }
    .buttn {
        margin-top: 30px;
    }
    input.form-col {
        height: 54px;
    }
    .rn-section-benefits
    {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    
    .rn-section-keypadding {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .rn-section-ctaform
    {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}


@media only screen and (max-width: 425px) and (min-width: 320px)
{

    .height-920 {
        height: 807px;
    }
    .stateMap1 {
        width: 78%;
        float: none;
        text-align: center;
        margin: 0 auto;
    }
    .inner {
        text-align: center;
    }
    .key-service ul li {
        font-size: 16px;
        padding: 8px 0px;
        background-image: none;
    }
    h5.subtitle {
        font-size: 20px;
        padding: 15px;
        width: 260px;
    }
    .buttn {
        margin-top: 30px;
    }
    input.form-col {
        height: 54px;
    }
    .rn-section-benefits
    {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    
    .rn-section-keypadding {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .rn-section-ctaform
    {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .button1 {
        padding: 20px 20px;
        margin-right: 0px;
        font-size: 18px;
    }
}


/* End Responsiveness */