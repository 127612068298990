p.banner-descrip.color-white {
    width: 70%;
    margin: 0 auto;
  }

  
.slider-internal {
    padding-top: 338px;
    padding-bottom: 337px;
}
.secondrow {
    padding-top: 70px;
}

.serv_text {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: #000;
    font-family: 'Nunito Sans', sans-serif;
}
img.iconsize {
    width: 180px;
    height: 180px;
}
img.iconsize1 {
    width: 120px;
    height: auto;
}
.three1
{
    background-color: rgb(255, 238, 0);
    width: 446px;
    padding-top: 80px;
    padding-bottom: 60px;
}
.serv_text1 {
    font-size: 26px;
    line-height: 36px;
    font-weight: 700;
    color: #000;
    font-family: "Nunito Sans", sans-serif;
    padding-bottom: 6px;
    padding-top: 15px;
}
.three2
{
    background-color: #fff;
    width: 446px;
    padding-top: 80px;
    padding-bottom: 60px;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.08);
}
.three2:hover
{
    background-color: rgb(255, 238, 0);
}

.serviceform {
    margin-top: 9%;
}
.serviceform .form-group textarea {
    background-color: #fff;
}
  
.serviceform input.btn-city-submit {
    background-color: #1E1E1E !important;
}

img.phonesmall {
    width: 450px;
    height: auto;
}

.phonepad {
    padding-top: 5%;
}

.phonepad-front {
    padding-top: 3%;
}

ul.tab-button {
    display: block;
}

.rn-default-tab.style-two .tab-button {
    margin-bottom: 30px;
    margin-top: 40px;
}

.rn-default-tab .tab-button .react-tabs__tab {
    padding: 20px 18px;
    background-color: #ffff01;
    margin-right: 10px;
}

.rn-default-tab .tab-button .rn-tab-button button {
    color: #000000;
    font-weight: 600;
}

.rn-default-tab .tab-button .react-tabs__tab.react-tabs__tab--selected {
    background: #f9f964;
}


.cta-arrow {
    --shift-distance: 0.25rem;
  
    display: inline-block;
    font-weight: 400;
    font-size: var(--copy-regular-size);
    line-height: 2rem;
    height: 2rem;
    color: var(--link-color);
    text-decoration: none;
    position: relative;
    padding-right: 2.5rem;
    transform: translateX(0);
    z-index: 1;
    transition: color 0.15s ease-in-out, transform 0.3s ease,
      -webkit-transform 0.3s ease;
    border-radius: 2em;
  
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0.125rem;
      right: 0;
      left: calc(100% - 2rem);
      border-radius: 2rem;
      height: 2rem;
      z-index: -1;
      background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3EArrow Forward%3C/title%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M268 112l144 144-144 144M392 256H100'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position-x: calc(100% - 0.4375rem);
      background-position-y: center;
      background-size: 1.125rem;
      background-color: #ffe01b;
      transition: left 0.3s cubic-bezier(0.34, 1.2, 0.64, 1), right 0.3s ease,
      background-position 0.3s ease;
    }
  
    &:hover {
      color: var(--link-color-hovered);
      transform: translateX(var(--shift-distance));
  
      
    }
  }
  


  .key-service-disp ul li {
    padding: 10px;
    background-repeat: no-repeat;
    background-size: 32px;
    background-position: 0;
    padding-left: 80px;
    list-style: none;
    color: #585858;
}

.key-service-disp ul li span {
    display: inline-block;
    -webkit-text-stroke: 1px #000;
    -webkit-text-fill-color: #000000;
    margin: 0;
    padding: 12px 0px;
    position: relative;
    font-size: 24px;
}

input[type="text"], input[type="password"], input[type="email"], input[type="number"], input[type="tel"], textarea {
    border: 1px solid #d1d1d1 !important;
}






/*form styles*/
#msform {
    text-align: center;
    position: relative;
    margin-top: 30px;
}

#msform fieldset {
    background: white;
    border: 0 none;
    border-radius: 10px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
    padding: 30px 30px;
    box-sizing: border-box;
    width: 90%;
    margin: 0 auto;

    /*stacking fieldsets above each other*/
    position: relative;
}

/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
    display: none;
}

/*inputs*/
#msform input, #msform textarea {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 0px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    color: #2C3E50;
    font-size: 15px;
}

#msform input:focus, #msform textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #ffff01;
    outline-width: 0;
    transition: All 0.5s ease-in;
    -webkit-transition: All 0.5s ease-in;
    -moz-transition: All 0.5s ease-in;
    -o-transition: All 0.5s ease-in;
}

/*buttons*/
#msform .action-button {
    width: 100px;
    background: #000;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 8px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px;
}

#msform .action-button:hover, #msform .action-button:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #ffff01;
}

#msform .action-button-previous {
    width: 100px;
    background: #C5C5F1;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 25px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px;
}

#msform .action-button-previous:hover, #msform .action-button-previous:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #C5C5F1;
}

/*headings*/
.fs-title {
    font-size: 22px;
    text-transform: uppercase;
    color: #2C3E50;
    margin-bottom: 10px;
    letter-spacing: 2px;
    font-weight: bold;
}

.fs-subtitle {
    font-weight: normal;
    font-size: 14px !important;
    color: #666;
    margin-bottom: 20px;
}

/*progressbar*/
#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    /*CSS counters to number the steps*/
    counter-reset: step;
}

#progressbar li {
    list-style-type: none;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    width: 33.33%;
    float: left;
    position: relative;
    letter-spacing: 1px;
}

#progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: block;
    font-size: 18px;
    color: #333;
    background: white;
    border-radius: 25px;
    margin: 0 auto 10px auto;
}

/*progressbar connectors*/
#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: white;
    position: absolute;
    left: -50%;
    top: 9px;
    z-index: -1; /*put it behind the numbers*/
}

#progressbar li:first-child:after {
    /*connector not needed before the first step*/
    content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before, #progressbar li.active:after {
    background: #ffff01;
    color: black;
}


/* Not relevant to this form */
.dme_link {
    margin-top: 30px;
    text-align: center;
}
.dme_link a {
    background: #FFF;
    font-weight: bold;
    color: #ffff01;
    border: 0 none;
    border-radius: 25px;
    cursor: pointer;
    padding: 5px 25px;
    font-size: 12px;
}

.dme_link a:hover, .dme_link a:focus {
    background: #C5C5F1;
    text-decoration: none;
}
 


.rn-section-every
{
    padding-top: 112px;
    padding-bottom: 120px;
}

.rn-section-truck
{
    padding-top: 285px;
    padding-bottom: 284px;
}

.rn-section-keypadding
{
    padding-top: 60px;
    padding-bottom: 60px;
}


.button2 {
	color: #ffff01;
    background: #000;
	border-radius: 8px;
	transition: .2s ease-in-out;
    padding: 20px 40px;
    margin-right: 20px;
    font-size: 22px;
}

.button2:hover {
	border-radius: 30px;
}


span.icon1 svg {
    font-size: 30px;
    background-color: #000000;
    color: #ffff01;
    height: 35px;
    border-radius: 5px;
    display: inline-block;
    position: relative;
    margin-right: 9px;
    min-width: 32px;
    min-height: 30px;
}








/* Start Responsiveness */

@media only screen and (max-width: 1440px) and (min-width: 1200px)
{
    .slider-internal {
        padding-top: 262px;
        padding-bottom: 262px;
    }
    .rn-section-every
    {
        padding-top: 73px;
        padding-bottom: 73px;
    }
    .phonepad {
        padding-top: 3%;
    }
    .rn-section-truck
    {
        padding-top: 204px;
        padding-bottom: 205px;
    }
    .rn-default-tab .rn-tab-content {
        padding: 50px 40px;
    }
    .key-service-disp ul li {
        font-size: 18px;
    }
    img.phonesmall {
        width: 430px;
        height: auto;
    }
    #msform input, #msform textarea {
        padding: 10px;
    }
    #msform {
        margin-top: 20px;
    }
    #progressbar {
        margin-bottom: 20px;
    }   
}



@media only screen and (max-width: 1200px) and (min-width: 1024px)
{
    .slider-internal {
        padding-top: 235px;
        padding-bottom: 235px;
    }
    .secondrow {
        padding-top: 40px;
    }
    .key-service-disp ul li {
        font-size: 18px;
        padding: 8px 10px;
    }
    img.phonesmall {
        width: 420px;
        height: auto;
    }
    .rn-default-tab .tab-button .react-tabs__tab {
        padding: 15px 10px;
        font-size: 16px;
    }
    .rn-default-tab .rn-tab-content {
        padding: 35px 30px;
    }
    .rn-section-every
    {
        padding-top: 68px;
        padding-bottom: 68px;
    }
    .rn-section-keypadding
    {
        padding-top: 50px;
        padding-bottom: 40px;
    }
    .rn-section-truck
    {
        padding-top: 223px;
        padding-bottom: 223px;
    }
    .button2 {
        padding: 15px 25px;
        font-size: 22px;
    }
}


@media only screen and (max-width: 1024px) and (min-width: 960px)
{
    .slider-internal {
        padding-top: 192px;
        padding-bottom: 193px;
    }
    .secondrow {
        padding-top: 40px;
    }
    .key-service-disp ul li {
        font-size: 18px;
        padding: 8px 60px;
    }
    img.phonesmall {
        width: 420px;
        height: auto;
    }
    .rn-default-tab .tab-button .react-tabs__tab {
        padding: 15px 10px;
        font-size: 16px;
    }
    img.iconsize {
        width: 160px;
        height: 160px;
    }
    .rn-default-tab .tab-button .react-tabs__tab {
        margin-bottom: 10px;
    }
    .rn-section-keypadding
    {
        padding-top: 50px;
        padding-bottom: 40px;
    }
    .rn-section-truck
    {
        padding-top: 180px;
        padding-bottom: 180px;
    }
    .button2 {
        padding: 15px 25px;
        font-size: 22px;
    }
    .key-service-disp ul li span {
        font-size: 20px;
    }
}


@media only screen and (max-width: 960px) and (min-width: 768px)
{
    .slider-internal {
        padding-top: 255px;
        padding-bottom: 254px;
    }
    .secondrow {
        padding-top: 40px;
    }
    .key-service-disp ul li {
        font-size: 18px;
        padding: 8px 0px;
    }
    .key-service-disp ul li span {
        font-size: 20px;
    }
    .phonepad {
        padding-top: 3%;
    }
    img.phonesmall {
        width: 420px;
        height: auto;
    }
    .rn-default-tab .tab-button .react-tabs__tab {
        padding: 15px 20px;
        font-size: 16px;
    }
    img.iconsize {
        width: 160px;
        height: 160px;
    }
    .rn-default-tab .tab-button .react-tabs__tab {
        margin-bottom: 10px;
    }
    .rn-default-tab .rn-tab-content {
        padding: 50px 40px;
    }
    .rn-section-every {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .rn-section-keypadding
    {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .rn-section-truck
    {
        padding-top: 159px;
        padding-bottom: 158px;
    }
    .button2 {
        padding: 15px 25px;
        font-size: 20px;
    }
}


@media only screen and (max-width: 768px) and (min-width: 520px)
{
    .slider-internal {
        padding-top: 255px;
        padding-bottom: 255px;
    }
    .secondrow {
        padding-top: 40px;
    }
    .key-service-disp ul li {
        font-size: 18px;
        padding: 8px 0px;
    }
    .key-service-disp ul li span {
        font-size: 18px;
    }
    .phonepad {
        padding-top: 6%;
    }
    img.phonesmall {
        width: 420px;
        height: auto;
    }
    .rn-default-tab .tab-button .react-tabs__tab {
        padding: 15px 20px;
        font-size: 16px;
    }
    img.iconsize {
        width: 160px;
        height: 160px;
    }
    .rn-default-tab .tab-button .react-tabs__tab {
        margin-bottom: 10px;
    }
    .rn-default-tab .rn-tab-content {
        padding: 50px 40px;
    }
    .rn-section-keypadding
    {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .rn-section-truck
    {
        padding-top: 171px;
        padding-bottom: 171px;
    }
    .button2 {
        padding: 15px 25px;
        font-size: 20px;
    }
    .section.sectmobile {
        display: block;
    }
}




@media only screen and (max-width: 520px) and (min-width: 425px)
{
    .slider-internal {
        padding-top: 270px;
        padding-bottom: 300px;
    }
    .secondrow {
        padding-top: 10px;
    }
    .phonepad ul {
        padding-left: 0px;
    }
    .key-service-disp ul li {
        font-size: 18px;
        padding: 8px 0px;
        background-image: none;
    }
    .key-service-disp ul li span {
        font-size: 18px;
    }
    ul.comp {
        margin-bottom: 0px;
    }
    img.phonesmall {
        width: 320px;
        height: auto;
    }
    .rn-default-tab .tab-button .react-tabs__tab {
        padding: 15px 20px;
        font-size: 16px;
    }
    img.iconsize {
        width: 160px;
        height: 160px;
    }
    #msform {
        margin-top: 10px;
    }
    #msform input, #msform textarea {
        padding: 10px;
    }
    .rn-default-tab .tab-button .react-tabs__tab {
        margin-bottom: 10px;
    }
    .rn-default-tab .rn-tab-content {
        padding: 40px 25px;
    }
    .rn-section-keypadding
    {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .rn-section-every {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .rn-section-truck
    {
        padding-top: 108px;
        padding-bottom: 108px;
    }
    #technology {
        padding-bottom: 40px;
    }
    img.ittruck {
        width: 230px;
        height: auto;
    }
    .tab-button-panel {
        margin: 0 20px;
    }
    ul.tab-button {
        display: inline-block !important;
        width: 100%;
        text-align: center;
    }
    .button2 {
        padding: 15px 25px;
        font-size: 18px;
    }
    .section.sectmobile {
        display: block;
    }
}


@media only screen and (max-width: 425px) and (min-width: 320px)
{
    .slider-internal {
        padding-top: 220px;
        padding-bottom: 250px;
    }
    .secondrow {
        padding-top: 10px;
    }
    .phonepad ul {
        padding-left: 0px;
    }
    .key-service-disp ul li {
        font-size: 18px;
        padding: 8px 0px;
        background-image: none;
    }
    .key-service-disp ul li span {
        font-size: 18px;
    }
    ul.comp {
        margin-bottom: 0px;
    }
    img.phonesmall {
        width: 320px;
        height: auto;
    }
    .rn-default-tab .tab-button .react-tabs__tab {
        padding: 15px 20px;
        font-size: 16px;
    }
    img.iconsize {
        width: 160px;
        height: 160px;
    }
    #msform {
        margin-top: 10px;
    }
    #msform input, #msform textarea {
        padding: 10px;
    }
    .rn-default-tab .tab-button .react-tabs__tab {
        margin-bottom: 10px;
    }
    .rn-default-tab .rn-tab-content {
        padding: 40px 25px;
    }
    .rn-section-keypadding
    {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .rn-section-every {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .rn-section-truck
    {
        padding-top: 60px;
        padding-bottom: 70px;
    }
    #technology {
        padding-bottom: 40px;
    }
    img.ittruck {
        width: 230px;
        height: auto;
    }
    .tab-button-panel {
        margin: 0 20px;
    }
    ul.tab-button {
        display: inline-block !important;
        width: 100%;
        text-align: center;
    }
    .button2 {
        padding: 15px 25px;
        font-size: 18px;
    }
    .section.sectmobile {
        display: block;
    }
}

/* End Responsiveness */